import styled from 'styled-components';

export const AppContainer = styled.div`
  min-height: 100vh;
  background-color: #fafdff;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 16px;
`;
