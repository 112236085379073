import React, { useState, useEffect } from 'react';
import extension from '../extension';
import Loading from '../components/Loading/Loading';

const withExtensionState = () => Component => {
  return props => {
    const [extensionState, setExtensionState] = useState(null);
    const { isCorrectBrowser = true, isCorrectOS = true } = props; //eslint-disable-line

    useEffect(() => {
      if (!isCorrectBrowser || !isCorrectOS) {
        setExtensionState('not-installed');
      } else {
        extension.checkExtensionStatus().then(extensionView => setExtensionState(extensionView));
      }
    }, [isCorrectBrowser, isCorrectOS]);

    if (extensionState === null) {
      return <Loading loading />;
    }

    return <Component {...props} extensionState={extensionState} />;
  };
};

export default withExtensionState;
