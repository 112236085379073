import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import NavBar from './components/NavBar/NavBar';
import { MainContainer, AppContainer } from './App.styles';
import UnsupportedMessage from './components/UnsupportedMessage/UnsupportedMessage';
import withDeviceFlags from './utils/withDeviceFlags';
import withExtensionState from './utils/withExtensionState';
import DeviceInUse from './components/DeviceInUse';
import PinCreation from './components/PinCreation';
import DonePin from './components/DonePin';
import Loading from './components/Loading/Loading';
import RouterSwitch from './components/Router/RouterSwitch';
import Route from './components/Route/Route';
import IdentificationManager from './components/IdentificationManager/IdentificationManager';
import RequestPinReset from './components/RequestPinReset';
import {
  HOME,
  PIN_CREATION,
  PIN_DONE,
  PIN_RESET_REQUEST,
  PIN_RESET,
  PENDENCIES,
  PENDENCIES_DONE,
} from './ducks/pageState';
import PinReset from './components/PinReset';
import { setRevocation } from './ducks/revogationState';
import PendenciesManager from './components/Pendencies/PendenciesManager';
import DonePendencies from './components/DonePendencies/DonePendencies';

const hasIdentification = cpf => cpf && cpf.length > 0;

const isSignUpAuthorized = identificationId => identificationId && identificationId.length > 0;

export class App extends React.Component {
  componentDidMount() {
    this.checkAndSetRevocation();
  }

  renderUnsuportedMessage = () => {
    const {
      operatingSystem,
      isCorrectBrowser,
      isCorrectOS,
      lastExtensionVersion,
      hasExtension,
    } = this.props;
    return (
      <UnsupportedMessage
        operatingSystem={operatingSystem}
        isCorrectBrowser={isCorrectBrowser}
        isCorrectOS={isCorrectOS}
        lastExtensionVersion={lastExtensionVersion}
        hasExtension={hasExtension}
      />
    );
  };

  getHomePageFallback = () => {
    const { extensionState } = this.props;

    return extensionState !== 'otp' ? this.renderUnsuportedMessage() : <DeviceInUse />;
  };

  checkAndSetRevocation = () => {
    const { updateRevocation } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const hasRevoked = urlParams.get('hasRevoked');

    if (hasRevoked === 'true') {
      updateRevocation();
    }
  };

  renderRouter = () => {
    const {
      isCorrectBrowser,
      isCorrectOS,
      hasExtension,
      extensionState,
      lastExtensionVersion,
    } = this.props;

    const canSignUp = isCorrectBrowser && isCorrectOS && hasExtension && lastExtensionVersion;
    return (
      <RouterSwitch>
        <Route
          page={HOME}
          permission={canSignUp && !['otp', 'approved'].includes(extensionState)}
          fallback={this.getHomePageFallback()}
          redirectTo={extensionState === 'approved' ? PIN_CREATION : undefined}
        >
          <IdentificationManager />
        </Route>
        <Route page={PIN_CREATION} permission={canSignUp} fallback={this.renderUnsuportedMessage()}>
          <PinCreation />
        </Route>
        <Route page={PIN_DONE} permission={canSignUp} fallback={this.renderUnsuportedMessage()}>
          <DonePin />
        </Route>
        <Route page={PIN_RESET_REQUEST} fallback={this.renderUnsuportedMessage()}>
          <RequestPinReset hasExtension={hasExtension} />
        </Route>
        <Route page={PIN_RESET} fallback={this.renderUnsuportedMessage()}>
          <PinReset hasExtension={hasExtension} />
        </Route>
        <Route page={PENDENCIES} fallback={this.renderUnsuportedMessage()}>
          <PendenciesManager />
        </Route>
        <Route page={PENDENCIES_DONE} fallback={this.renderUnsuportedMessage()}>
          <DonePendencies />
        </Route>
      </RouterSwitch>
    );
  };

  render() {
    const { cpf, identificationId, loading, stepper } = this.props;
    return (
      <AppContainer>
        <NavBar
          showStepper={Boolean(
            hasIdentification(cpf) && isSignUpAuthorized(identificationId) && stepper,
          )}
        />
        <MainContainer>
          <Loading loading={loading}>{this.renderRouter()}</Loading>
        </MainContainer>
      </AppContainer>
    );
  }
}

App.propTypes = {
  cpf: PropTypes.string.isRequired,
  identificationId: PropTypes.string.isRequired,
  isCorrectBrowser: PropTypes.bool.isRequired,
  isCorrectOS: PropTypes.bool.isRequired,
  hasExtension: PropTypes.bool.isRequired,
  operatingSystem: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  stepper: PropTypes.bool.isRequired,
  updateRevocation: PropTypes.func.isRequired,
  extensionState: PropTypes.string.isRequired,
  lastExtensionVersion: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  identification: { cpf, identificationId },
  stage: { stage, stepper },
  loading: { loading },
}) => ({ cpf, identificationId, stage, loading, stepper });

export default compose(
  connect(
    mapStateToProps,
    { updateRevocation: setRevocation },
  ),
  withDeviceFlags(),
  withExtensionState(),
)(App);
