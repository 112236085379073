import React, { useState, useEffect } from 'react';
import Bowser from 'bowser';
import Loading from '../components/Loading/Loading';
import extension from '../extension';

const withDeviceFlags = () => Component => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const operatingSystem = browser.getOSName();
  const isCorrectBrowser = browser.getBrowserName() === 'Chrome';
  const isCorrectOS = ['Windows', 'macOS', 'Linux'].includes(operatingSystem);

  return props => {
    const [hasExtension, setHasExtension] = useState(false);
    const [lastExtensionVersion, setLastExtensionVersion] = useState(false);

    const baseFlags = {
      operatingSystem,
      isCorrectBrowser,
      isCorrectOS,
    };

    if (!isCorrectBrowser || !isCorrectOS) {
      return <Component {...props} {...baseFlags} hasExtension={false} />;
    }

    useEffect(() => {
      if (process.env.REACT_APP_EXTENSION_ID) {
        extension.checkInstallation().then(([flag, versionId]) => {
          setHasExtension(flag);
          setLastExtensionVersion(versionId === process.env.REACT_APP_EXTENSION_VERSION_ID);
        });
      } else {
        setHasExtension(true);
        setLastExtensionVersion(true);
      }
    }, []);

    const deviceFlags = {
      hasExtension,
      lastExtensionVersion,
      ...baseFlags,
    };

    if (hasExtension === null) {
      return <Loading loading />;
    }

    return <Component {...props} {...deviceFlags} />;
  };
};

export default withDeviceFlags;
