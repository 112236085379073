import styled from 'styled-components';
import { Card, CardFooter as RawCardFooter } from 'liber-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledCard = styled(Card)`
  margin-top: 48px;
  width: fit-content;
  align-items: unset;
`;

export const CardFooter = styled(RawCardFooter)`
  width: 100%;
`;
