import React from 'react';
import PropTypes from 'prop-types';
import {
  CardStyled,
  RadioStyled,
  Container,
  Text,
  Image,
  ImgTextContainer,
} from './RadioButton.styles';

const RadioButton = ({ children, checked, onChange, id, imgSrc }) => {
  return (
    <CardStyled checked={checked} onClick={onChange} version={2}>
      {imgSrc ? (
        <Container>
          <ImgTextContainer>
            <Image alt="" src={imgSrc} />
            <Text checked={checked}>{children}</Text>
          </ImgTextContainer>
          <RadioStyled id={`radio-button-${id}`} checked={checked} />
        </Container>
      ) : (
        <RadioStyled id={`radio-button-${id}`} checked={checked}>
          {children}
        </RadioStyled>
      )}
    </CardStyled>
  );
};

RadioButton.propTypes = {
  children: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
};

RadioButton.defaultProps = {
  imgSrc: null,
};

export default RadioButton;
