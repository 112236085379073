import styled, { css } from 'styled-components';
import { TextField, SelectField as RawSelect } from 'liber-components';

export const CardContent = styled.div`
  padding: 24px 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SectionTitle = styled.span`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #405f71;
  width: 100%;
`;

export const MandatoryFields = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #798e9b;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const HolderInfoContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ marginTop }) =>
    marginTop
      ? css`
          margin-top: 16px;
        `
      : null}
`;

export const FormField = styled(TextField).attrs(() => ({
  version: 2,
  width: '346px',
}))`
  margin-bottom: 16px;
`;

export const SelectField = styled(RawSelect).attrs(() => ({ version: 2, searchable: true }))`
  margin-bottom: 16px;
  margin-top: 16px;
  width: 346px;
  --closed-label-font-size: 16px;
`;
