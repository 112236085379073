import { MdClose, MdImage, MdPlus } from 'liber-components';
import styled from 'styled-components';

export const IconContainer = styled.div`
  margin-right: 17px;
  margin-top: ${props => (props.uploadedFile ? '0' : '12px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  box-shadow: 0 0.5px 2px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  bottom: 12px;
  left: 11px;
  background-color: #ffffff;
`;

export const PlusIcon = styled(MdPlus)`
  width: 16px;
  height: 16px;
  fill: #009dff;
`;

export const InputFile = styled.input`
  overflow: hidden;
  position: absolute;
  & + label {
    font-family: Roboto;
    font-size: 16px;
    color: #009dff;
    display: inline-block;
  }
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const getBorder = ({ isDragAccept, uploadedFile }) => {
  if (isDragAccept) {
    return 'solid 1px #009dff';
  } else if (uploadedFile) {
    return 'solid 1px #e8ebee';
  }
  return 'dashed 1px #009dff';
};

export const UploadFileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 366px;
  max-width: 366px;
  height: 65px;
  font-size: 14px;
  justify-content: ${({ uploadedFile }) =>
    uploadedFile ? 'space-between' : null};
  border: ${props => getBorder(props)};
  background-color: ${props => (props.uploadedFile ? '#fafdff' : '#f0f9ff')};
  padding: 0 28px;
  box-sizing: border-box;
  position: relative;
  &:hover {
    cursor: ${props => (props.uploadedFile ? 'default' : 'pointer')};
  }
`;

export const UploadLabel = styled.label`
  color: ${props => (props.uploadedFile ? '#798e9b' : '#009dff')};
  cursor: ${props => (props.uploadedFile ? 'default' : 'pointer')};
`;

export const StyledMdImage = styled(MdImage)`
  fill: ${props => (props.uploadedFile ? '#b6c2c9' : '#009dff')};
  width: 32px;
  height: 32px;
`;

export const StyledMdClose = styled(MdClose)`
  width: 16px;
  height: 16px;
  fill: #b6c2c9;
  cursor: pointer;
  z-index: 99;
`;
