import { toast } from 'liber-components/components/Toast';
import api from '../api/api';
import { COOKIE_IDENTIFICATION_ID, COOKIE_IN_PROGRESS } from '../utils/constants';
import { getInitialState, saveCookieState } from '../utils/CookieHelper';
import { setRevocation } from './revogationState';

import { setLoading } from './loadingState';

const SET_CREDENTIALS = 'liber-id/identification/SET_CREDENTIALS';
const IDENTIFICATE_CPF = 'liber-id/identification/IDENTIFICATE_CPF';

const initialState = {
  cpf: '',
  hasAccount: false,
  identificationId: '',
  token: '',
};

const checkIdAndToken = (id, token) => id && token;

export default function reducer(
  state = getInitialState(COOKIE_IDENTIFICATION_ID, initialState),
  action = {},
) {
  const { type, payload } = action;
  switch (type) {
    case SET_CREDENTIALS: {
      const { identificationId, token } = payload;
      const finalState = { ...state, ...payload };
      saveCookieState(COOKIE_IDENTIFICATION_ID, finalState);

      if (checkIdAndToken(identificationId, token)) {
        saveCookieState(COOKIE_IN_PROGRESS, 'in-progress', false);
      }

      return finalState;
    }
    default:
      return state;
  }
}

export function setCredentials(payload = {}) {
  return { type: SET_CREDENTIALS, payload };
}

export function identificateCPF(cpf) {
  return dispatch => {
    dispatch({ type: IDENTIFICATE_CPF });
    dispatch(setLoading(true));

    return api
      .identificateCPF(cpf)
      .then(response => {
        const {
          data: { id, token },
        } = response;
        const hasAccount = !checkIdAndToken(id, token);
        dispatch(setCredentials({ cpf, hasAccount, identificationId: id, token }));
      })
      .catch(error => {
        try {
          const {
            response: {
              data: { can_revoke: canRevoke, messages },
            },
          } = error;

          if (canRevoke) {
            dispatch(setCredentials({ cpf, hasAccount: true }));
            dispatch(setRevocation(false));
          } else {
            const [info] = messages;
            toast({ message: 'Erro', info }, 'error', 5000);
          }
        } catch (e) {
          console.error(e);
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}
