import { applyMiddleware, createStore } from 'redux';

import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import immutable from 'redux-immutable-state-invariant';

import reducers from '../ducks';

let middleware;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  const { composeWithDevTools } = require('redux-devtools-extension'); // eslint-disable-line global-require
  const composeEnhancers = composeWithDevTools({});
  middleware = composeEnhancers(applyMiddleware(promise, thunk, logger, immutable()));
} else {
  middleware = applyMiddleware(promise, thunk);
}

export default createStore(reducers, middleware);
