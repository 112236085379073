import styled, { css } from 'styled-components';
import { Card, RadioButton } from 'liber-components';

export const CardStyled = styled(Card)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 18px 24px;
  margin-bottom: 8px;
  width: 100%;
  cursor: pointer;
  ${({ checked }) =>
    checked
      ? css`
          border: 1px solid #009dff;
          background-color: #f0f9ff;
        `
      : null}
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ImgTextContainer = styled(Container)`
  width: 192px;
`;

export const Image = styled.img`
  height: 71px;
  width: auto;
`;

export const Text = styled.span`
  font-family: Roboto;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.41px;
  text-align: left;
  ${({ checked }) =>
    checked
      ? css`
          font-weight: 600;
          color: #405f71;
        `
      : css`
          font-weight: normal;
          color: #798e9b;
        `}
`;


export const RadioStyled = styled(RadioButton).attrs(({ children }) => ({
  hasChildren: Boolean(children),
}))`
  ${({ checked }) =>
    checked
      ? css`
          color: #405f71;
        `
      : null}
  ${({ hasChildren }) =>
    !hasChildren
      ? css`
          & > div {
            padding: 0px 10px;
          }
        `
      : null}
`;
