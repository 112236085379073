import React from 'react';

import { Wrapper, Img } from './DeviceInUse.styles';
import foot from '../../assets/images/hare-foot.png';
import HeaderMessages from '../HeaderMessages/HeaderMessages';

export const DeviceInUse = () => (
  <Wrapper>
    <Img src={foot} alt="Localização" />
    <HeaderMessages
      title="Identificação já existente"
      details={
        <>
          Você já possui uma identificação neste dispositivo. <br />
          Qualquer dúvida, entre em contato com o nosso suporte.
        </>
      }
    />
  </Wrapper>
);

export default DeviceInUse;
