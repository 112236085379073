import styled from 'styled-components';

export const FooterMessage = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #798e9b;
`;

export const FooterLink = styled.span`
  cursor: pointer;
  color: #009dff;
`;

export const FooterSubMessage = styled(FooterMessage)`
  font-weight: 400;
`;
