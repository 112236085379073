import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { WEBCAM_FLOW, UPLOAD_FLOW } from '../../utils/constants';
import UserIdentification from '../UserIdentification/UserIdentification';
import Revogation from '../Revogation/Revogation';
import UserDocument from '../UserDocument/UserDocument';
import UserDocumentCam from '../UserDocumentCam';
import Selfie from '../Selfie';
import SelfieCam from '../SelfieCam';
import UserContact from '../UserContact/UserContact';
import Location from '../Location';
import Address from '../Address/Address';
import Done from '../Done';

const hasIdentification = cpf => cpf && cpf.length > 0;

const isSignUpAuthorized = identificationId => identificationId && identificationId.length > 0;

const uploadFlow = [
  <UserDocument />,
  <Selfie />,
  <UserContact />,
  <Address />,
  <Location />,
  <Done />,
];

const webcamFlow = [
  <UserDocumentCam />,
  <SelfieCam />,
  <UserContact />,
  <Address />,
  <Location />,
  <Done />,
];

const flows = {
  [WEBCAM_FLOW]: webcamFlow,
  [UPLOAD_FLOW]: uploadFlow,
};

export class IdentificationManager extends Component {
  componentDidMount() {
    const { stage } = this.props;
    if (stage !== 5) {
      window.onbeforeunload = function checkLeave() {
        return 'Sair do Site? É possivel que as alterações feitas não sejam salvas.';
      };
    }
  }

  renderFlow = () => {
    const { flow } = this.props;
    return this.renderStage(flows[flow]);
  };

  renderStage = flow => {
    const { stage } = this.props;
    return flow[stage] || <Done />;
  };

  render() {
    const { cpf, hasAccount, hasRevoked, identificationId } = this.props;

    const isIdentified = hasIdentification(cpf);

    if (!isIdentified || (isIdentified && hasAccount && hasRevoked)) {
      return <UserIdentification />;
    }
    if (isIdentified && isSignUpAuthorized(identificationId)) {
      return this.renderFlow();
    }
    return <Revogation />;
  }
}

IdentificationManager.propTypes = {
  cpf: PropTypes.string.isRequired,
  hasRevoked: PropTypes.bool.isRequired,
  hasAccount: PropTypes.bool.isRequired,
  identificationId: PropTypes.string.isRequired,
  stage: PropTypes.number.isRequired,
  flow: PropTypes.number.isRequired,
};

const mapStateToProps = ({
  identification: { cpf, hasAccount, identificationId },
  stage: { stage, flow },
  revogation: { hasRevoked },
}) => ({ cpf, hasAccount, identificationId, hasRevoked, stage, flow });

export default connect(mapStateToProps)(IdentificationManager);
