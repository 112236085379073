import React from 'react';
import PropTypes from 'prop-types';
import { Title, SubTitle, Details } from './HeaderMessages.styles';

const HeaderMessages = ({ title, subTitle, details }) => {
  return (
    <>
      <Title>{title}</Title>
      {subTitle ? <SubTitle>{subTitle}</SubTitle> : null}
      {details ? <Details>{details}</Details> : null}
    </>
  );
};

HeaderMessages.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.node,
  details: PropTypes.node,
};

HeaderMessages.defaultProps = {
  title: null,
  subTitle: null,
  details: null,
};

export default HeaderMessages;
