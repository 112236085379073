import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  HoldDocument,
  Wrapper,
  DocumentImg,
  UploadImageContainer,
  ImageHelper,
  StyledPrimaryButton,
} from './Selfie.styles';
import { Button, BackButton, ButtonContainer, PreviewContainer } from '../Cam/Cam.styles';
import { Image } from '../UserDocument/UserDocument.styles';
import selfie from '../../assets/images/selfie.png';
import UploadFile from '../UploadFile/UploadFile';
import HeaderMessages from '../HeaderMessages/HeaderMessages';
import { uploadDocument } from '../../ducks/uploadTriggers';
import { incrementStage } from '../../ducks/stageState';
import { useUpload } from '../../utils/useCam';

const handleUpload = (file, sender, token, identificationId, callback) => () => {
  sender(
    {
      file,
      identificationId,
      token,
      documentKind: 'selfies',
    },
    callback,
  );
};

const handleTryAgain = (setShowPreview, clearFile) => () => {
  setShowPreview(false);
  clearFile();
};

const handleShowPreview = setter => () => {
  setter(true);
};

const handleChangeFile = setUploadImage => file => {
  if (file) setUploadImage(file);
};

export const Selfie = ({ uploadFile, token, identificationId, goToNextStage }) => {
  const { showPreview, setShowPreview, file, clearFile, setUploadImage, uploadImg } = useUpload();

  if (showPreview && uploadImg) {
    return (
      <Wrapper>
        <HeaderMessages
          subTitle="As informações estão legíveis?"
          details="Lembre-se que nós também precisamos conseguir lê-las."
        />
        <PreviewContainer>
          <Image src={uploadImg} alt="Visualização do Documento" />
        </PreviewContainer>
        <ButtonContainer>
          <BackButton onClick={handleTryAgain(setShowPreview, clearFile)}>Trocar Foto</BackButton>
          <Button
            version={2}
            onClick={handleUpload(file, uploadFile, token, identificationId, goToNextStage)}
            disable={!file}
          >
            Continuar
          </Button>
        </ButtonContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <HeaderMessages
        title="Fotografia"
        subTitle={
          <>
            Agora, precisamos de uma <HoldDocument>foto sua segurando seu documento.</HoldDocument>
          </>
        }
        details={
          <>
            Utilize o mesmo documento enviado na etapa anterior.
            <br />
            Seu rosto e o documento devem aparecer nitidamente na fotografia.
          </>
        }
      />
      <UploadImageContainer>
        <DocumentImg src={selfie} alt="Selfie!" />
        <UploadFile
          accept={['image/png', 'image/jpeg']}
          onChange={handleChangeFile(setUploadImage)}
          uploadedFile={file || {}}
          onRemoveFile={clearFile}
        >
          Selecione um arquivo do seu computador
        </UploadFile>
        <ImageHelper>*Formatos permitidos: PNG ou JPG</ImageHelper>
        <StyledPrimaryButton
          version={2}
          onClick={handleShowPreview(setShowPreview)}
          disabled={!file}
        >
          Continuar
        </StyledPrimaryButton>
      </UploadImageContainer>
    </Wrapper>
  );
};

Selfie.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  goToNextStage: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  identificationId: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  uploadFile: uploadDocument,
  goToNextStage: incrementStage,
};

const mapStateToProps = ({ identification: { identificationId, token } }) => ({
  identificationId,
  token,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Selfie);
