import Cookie from 'js-cookie';
import {
  REVOCATION_COOKIE,
  COOKIE_STAGE_ID,
  COOKIE_IDENTIFICATION_ID,
  COOKIE_IN_PROGRESS,
} from './constants';

export function getInitialState(cookieId, initialState) {
  const cookieState = Cookie.getJSON(cookieId);
  if (cookieState) {
    return cookieState;
  }
  return initialState;
}

export function saveCookieState(cookieId, state, expires = true) {
  Cookie.set(cookieId, state, {
    expires: expires ? parseInt(process.env.REACT_APP_COOKIE_EXPIRATION, 10) : undefined,
  });
}

export function checkCookie(cookieId) {
  const cookie = Cookie.get(cookieId);
  return !!cookie;
}

export function removeAllCookies() {
  const cookiesIds = [
    COOKIE_IDENTIFICATION_ID,
    COOKIE_IN_PROGRESS,
    COOKIE_STAGE_ID,
    REVOCATION_COOKIE,
  ];
  cookiesIds.forEach(cookieId => {
    Cookie.remove(cookieId);
  });
}
