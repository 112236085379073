/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'liber-components';
import MobileStoreButton from '../MobileStoreButton/MobileStoreButton';
import ImageMessage from '../ImageMessage/ImageMessage';
import selfie from '../../assets/images/selfie.png';
import mobileBunny from '../../assets/images/lebre-smartphone.png';
import update from '../../assets/images/update.png';

const links = {
  browser: 'https://www.google.pt/intl/pt-BR/chrome/',
  extension: process.env.REACT_APP_EXTENSION_LINK,
};

const openLink = messageType => () => {
  window.open(links[messageType], '__blank');
};

const messages = {
  iOS: {
    title: 'Identificação pelo app',
    imgSrc: mobileBunny,
    imgCropped: false,
    message: (
      <>
        Para fazer a identificação pelo celular, <br />
        baixe o app Liber Capital:
      </>
    ),
    children: <MobileStoreButton store="ios" />,
  },
  Android: {
    title: 'Identificação pelo app',
    imgSrc: mobileBunny,
    imgCropped: false,
    message: (
      <>
        Para fazer a identificação pelo celular, <br />
        baixe o app Liber Capital:
      </>
    ),
    children: <MobileStoreButton store="android" />,
  },
  extension: {
    title: 'Olá, seja bem-vindo(a)',
    imgSrc: selfie,
    imgCropped: false,
    message: `Para fazer a identificação, você precisa
    instalar a extensão Liber ID no Chrome.`,
    children: (
      <PrimaryButton size="large" onClick={openLink('extension')}>
        Baixar extensão
      </PrimaryButton>
    ),
  },
  extensionVersion: {
    title: 'Nova versão da extensão',
    imgSrc: update,
    message: `Para continuar, você precisa atualizar a extensão
    para a última versão disponível na Chrome Store.`,
    children: (
      <PrimaryButton size="large" onClick={openLink('extension')}>
        Atualizar extensão
      </PrimaryButton>
    ),
  },
  browser: {
    title: 'Olá, seja bem-vindo(a)',
    imgSrc: selfie,
    imgCropped: false,
    message: `Para fazer a identificação, você precisa usar o Chrome
    como navegador e instalar a extensão Liber ID.`,
    children: (
      <PrimaryButton size="large" onClick={openLink('browser')}>
        Baixar o Chrome
      </PrimaryButton>
    ),
  },
};

const getMessageProps = args => {
  const {
    operatingSystem,
    isCorrectBrowser,
    isCorrectOS,
    lastExtensionVersion,
    hasExtension,
  } = args;

  if (!isCorrectOS) {
    return messages[operatingSystem];
  }
  if (!isCorrectBrowser) {
    return messages.browser;
  }
  if (!hasExtension) {
    return messages.extension;
  }
  if (!lastExtensionVersion) {
    return messages.extensionVersion;
  }
  return '';
};

const UnsupportedMessage = props => {
  const imgProps = getMessageProps(props);

  return <ImageMessage {...imgProps} />;
};

UnsupportedMessage.propTypes = {
  operatingSystem: PropTypes.string.isRequired,
  isCorrectBrowser: PropTypes.bool.isRequired,
  isCorrectOS: PropTypes.bool.isRequired,
  lastExtensionVersion: PropTypes.bool.isRequired,
  hasExtension: PropTypes.bool.isRequired,
};

export default UnsupportedMessage;
