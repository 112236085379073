import api from '../api/api';
import { handleError } from '../utils/ErrorHelper';
import { setLoading } from './loadingState';

export const UPLOAD_DOCUMENT = 'liber-id/sign-up/UPLOAD_DOCUMENT';
export const UPLOAD_CONTACT = 'liber-id/sign-up/UPLOAD_CONTACT';

export function uploadDocument({ file, identificationId, token, documentKind }, onUpload) {
  return dispatch => {
    dispatch({ type: UPLOAD_DOCUMENT });
    dispatch(setLoading(true));

    return api
      .sendImage(file, identificationId, token, documentKind)
      .then(() => (onUpload ? onUpload() : null))
      .catch(handleError)
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function uploadContact({ identificationId, token, userContact }, onUpload) {
  return dispatch => {
    dispatch({ type: UPLOAD_CONTACT });
    dispatch(setLoading(true));

    return api
      .sendContactInformation(identificationId, token, userContact)
      .then(() => (onUpload ? onUpload() : null))
      .catch(handleError)
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}
