import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  Title,
  Message,
  Wrapper,
  ChildContainer,
  CroppedImage,
} from './ImageMessage.styles';

const ImageMessage = props => {
  const { imgSrc, title, message, children, imgCropped, size } = props;
  const ImageComponent = imgCropped ? CroppedImage : Image;
  return (
    <Wrapper>
      <ImageComponent src={imgSrc} size={size} />
      <Title size={size}>{title}</Title>
      <Message size={size}>{message}</Message>
      {children ? <ChildContainer>{children}</ChildContainer> : null}
    </Wrapper>
  );
};

ImageMessage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgCropped: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'regular', 'medium']),
};

ImageMessage.defaultProps = {
  children: null,
  imgCropped: true,
  size: 'regular',
};

export default ImageMessage;
