import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {
  Circle,
  IconContainer,
  InputFile,
  PlusIcon,
  StyledMdImage,
  StyledMdClose,
  UploadFileContainer,
  UploadLabel,
  IconTextContainer,
} from './UploadFile.styles';

export class UploadFile extends React.Component {
  handleFileDrop = acceptedFiles => {
    const { onChange } = this.props;
    const [file] = acceptedFiles;
    onChange(file);
  };

  onRemoveClick = event => {
    const { onRemoveFile } = this.props;
    event.preventDefault();
    onRemoveFile();
  };

  render() {
    const {
      accept,
      children,
      uploadedFile,
      uploadedFile: { name },
    } = this.props;
    const fileName = uploadedFile ? name : '';
    const hasUploadedFile = uploadedFile instanceof File;

    return (
      <Dropzone
        multiple={false}
        accept={accept}
        onDrop={this.handleFileDrop}
        disabled={hasUploadedFile}
      >
        {({ getRootProps, getInputProps, isDragAccept }) => (
          <UploadFileContainer
            {...getRootProps()}
            isDragAccept={isDragAccept}
            uploadedFile={hasUploadedFile}
          >
            <InputFile {...getInputProps()} />
            <IconTextContainer>
              <IconContainer uploadedFile={hasUploadedFile}>
                <StyledMdImage uploadedFile={hasUploadedFile} />
                {!hasUploadedFile && (
                  <Circle>
                    <PlusIcon />
                  </Circle>
                )}
              </IconContainer>
              <UploadLabel uploadedFile={hasUploadedFile} htmlFor="file">
                {fileName || children}
              </UploadLabel>
            </IconTextContainer>
            {hasUploadedFile && <StyledMdClose onClick={this.onRemoveClick} />}
          </UploadFileContainer>
        )}
      </Dropzone>
    );
  }
}

UploadFile.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string.isRequired),
  actionURL: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  uploadedFile: PropTypes.shape({ name: PropTypes.string }),
  getFile: PropTypes.func,
  onUploadFiles: PropTypes.func,
  onRemoveFile: PropTypes.func,
  onChange: PropTypes.func,
};

UploadFile.defaultProps = {
  accept: ['image/png', 'image/jpeg', 'application/pdf'],
  actionURL: '',
  children: '',
  uploadedFile: {},
  getFile: () => {},
  onUploadFiles: () => {},
  onRemoveFile: () => {},
  onChange: () => {},
};

export default UploadFile;
