import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormTemplate from '../FormTemplate/FormTemplate';
import {
  TextFieldStyled,
  PrimaryButtonStyled,
  Bold,
  Container,
  RequiredLabel,
} from './UserAddress.styles';
import { getAddress, addressShape } from '../../ducks/addressState';
import AddressForm from '../AddressForm/AddressForm';

const LABELS = {
  residential: 'residencial',
  business: 'comercial',
};

export class UserAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zipCode: '',
    };
  }

  getAddress = event => {
    const { searchAddress, addressStage } = this.props;
    const { zipCode } = this.state;
    event.preventDefault();

    const formattedCode = this.formatZipCode(zipCode);

    searchAddress(addressStage, formattedCode);
  };

  formatZipCode = zipCode => zipCode.split('-').join('');

  changeZipCode = value => this.setState({ zipCode: value });

  render() {
    const { addressStage } = this.props;
    const { zipCode } = this.state;
    const { hasFetched, address } = this.props[addressStage];
    return (
      <FormTemplate
        title="Endereço"
        subTitle={
          <>
            Precisamos do seu endereço <Bold>{`${LABELS[addressStage]}`}</Bold>.
          </>
        }
        details={
          hasFetched
            ? 'Por favor, preencha todos os campos abaixo:'
            : 'Informe o CEP do seu endereço residencial:'
        }
      >
        {hasFetched ? (
          <>
            <Container>
              <TextFieldStyled
                value={zipCode}
                width="346px"
                onChange={this.changeZipCode}
                mask="99999-999"
                label="CEP*"
                version={2}
              />
              <AddressForm address={address} />
            </Container>
          </>
        ) : (
          <form id="zip-code-form" onSubmit={this.getAddress}>
            <Container>
              <TextFieldStyled
                value={zipCode}
                width="346px"
                onChange={this.changeZipCode}
                mask="99999-999"
                label="CEP*"
                version={2}
              />
              <RequiredLabel>*Campos obrigatórios</RequiredLabel>
              <PrimaryButtonStyled disabled={zipCode.length < 9} version={2}>
                Continuar
              </PrimaryButtonStyled>
            </Container>
          </form>
        )}
      </FormTemplate>
    );
  }
}

UserAddress.propTypes = {
  addressStage: PropTypes.string.isRequired,
  residential: PropTypes.shape({
    hasFetched: PropTypes.bool.isRequired,
    address: addressShape.isRequired,
  }).isRequired,
  business: PropTypes.shape({
    hasFetched: PropTypes.bool.isRequired,
    address: addressShape.isRequired,
  }).isRequired,
  searchAddress: PropTypes.func.isRequired,
};

const mapStateToProps = ({ address: { addressStage, residential, business } }) => ({
  addressStage,
  residential,
  business,
});

const mapDispatchToProps = {
  searchAddress: getAddress,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAddress);
