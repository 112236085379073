import styled, { css } from 'styled-components';

export const GoogleImg = styled.img`
  ${({ device }) =>
    device === 'mobile'
      ? css`
          width: 135px;
        `
      : css`
          height: 56px;
          margin-right: 16px;
        `}
`;

export const AppleImg = styled.img`
  height: ${({ device }) => (device === 'mobile' ? '40px' : '56px')};
`;
