import SimpleMaskMoney from 'simple-mask-money';

SimpleMaskMoney.args = {
  decimalSeparator: ',',
  fixed: true,
  fractionDigits: 2,
  suffix: '',
  thousandsSeparator: '.',
};

export const isValueEmpty = value => value === '0,00' || value === '0' || value === '';

export const formatMoney = value => {
  if (isValueEmpty(value)) {
    return '';
  }
  return SimpleMaskMoney.format(value);
};

export const formatToNumber = value => SimpleMaskMoney.formatToNumber(value);
