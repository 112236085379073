import styled from 'styled-components';
import { PrimaryButton } from 'liber-components';

export const Strong = styled.span`
  color: #009dff;
`;

export const RadioContainer = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 22px;
`;

export const PrimaryButtonStyled = styled(PrimaryButton)`
  margin-top: 56px;
  width: 256px;
`;
