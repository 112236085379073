import styled from 'styled-components';

const BaseText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
`;

export const Title = styled(BaseText)`
  font-size: 32px;
  font-weight: bold;
  line-height: 34px;
  letter-spacing: normal;
  color: #405f71;
  margin-bottom: 8px;
`;

export const SubTitle = styled(BaseText)`
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: normal;
  color: #344e5d;
`;

export const Details = styled(BaseText)`
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #798e9b;
`;
