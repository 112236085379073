import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import FormTemplate from '../../../FormTemplate/FormTemplate';
import { BlueBold, CardContent, TextField } from '../Pendencies.styles';
import { SuccessButton } from './Patrimony.styles';
import { isValueEmpty, formatToNumber } from '../../../../utils/MoneyHelper';
import { registerFinancialData } from '../../../../ducks/pendenciesState';

export const Patrimony = ({ onConfirm, idJWT, income, redirectData }) => {
  const [netWorth, setNetWorth] = useState('');
  return (
    <FormTemplate
      title="Patrimônio"
      subTitle={
        <>
          E qual é o seu <BlueBold>patrimônio total</BlueBold>?
        </>
      }
      details={
        <>
          O patrimônio total é a soma do valor de todos <br />
          seus ativos - financeiros ou não (pode ser estimado).
        </>
      }
    >
      <form
        id="patrimony-form"
        onSubmit={event => {
          event.preventDefault();
          onConfirm(idJWT, { income, netWorth: formatToNumber(netWorth) }, redirectData);
        }}
      >
        <CardContent>
          <TextField label="Patrimônio" value={netWorth} onChange={setNetWorth} />
          <SuccessButton disabled={isValueEmpty(netWorth)}>Concluir</SuccessButton>
        </CardContent>
      </form>
    </FormTemplate>
  );
};

Patrimony.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  income: PropTypes.string.isRequired,
  idJWT: PropTypes.string.isRequired,
  redirectData: PropTypes.shape({
    service: PropTypes.string,
    targetUrl: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ pendencies: { income, idJWT, redirectData } }) => ({
  income,
  idJWT,
  redirectData,
});

const mapDispatchToProps = {
  onConfirm: registerFinancialData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Patrimony);
