import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserStatusCheck from './UserStatusCheck';
import {
  SELFIE_STAGE,
  STATUS_STAGE,
  REQUESTED_STAGE,
  INACTIVE_STAGE,
} from '../../ducks/pinResetState';
import UnregisteredUser from './UnregisteredUser';
import PinSelfie from './PinSelfie';
import ImageMessage from '../ImageMessage/ImageMessage';
import clock from '../../assets/images/clock.svg';

export const RequestPinReset = ({ stage, hasExtension }) => {
  switch (stage) {
    case STATUS_STAGE:
      return <UserStatusCheck />;
    case SELFIE_STAGE:
      return <PinSelfie hasExtension={hasExtension} />;
    case REQUESTED_STAGE:
      return (
        <ImageMessage
          title="Agora é só aguardar!"
          message={
            <>
              Vamos analisar a sua foto e, se estiver tudo certo, enviaremos{' '}
              <br /> uma notificação com o link para a criação de um novo PIN.
            </>
          }
          imgSrc={clock}
          size="medium"
          imgCropped={false}
        />
      );

    case INACTIVE_STAGE:
    default:
      return <UnregisteredUser />;
  }
};

RequestPinReset.propTypes = {
  stage: PropTypes.string.isRequired,
  hasExtension: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ pinReset: { stage } }) => ({ stage });

export default connect(mapStateToProps)(RequestPinReset);
