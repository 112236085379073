import styled from 'styled-components';

export const ContentBox = styled.div`
  padding: 13px 16px;
`;

export const Header = styled(ContentBox)`
  border-bottom: 1px solid #e8ebee;
`;

export const Wrapper = styled.div`
  min-width: 350px;
`;

export const Title = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #405f71;
`;

export const SecondaryText = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.01px;
  text-align: left;
  color: #798e9b;
`;

export const ContactsContainer = styled.div`
  padding-top: 24px;
`;
