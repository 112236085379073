import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HeaderMessages from '../HeaderMessages/HeaderMessages';
import { Footer } from './UserDocumentCam.styles';
import { FooterMessage, FooterLink } from '../Base.styles';
import {
  Wrapper,
  DocumentSide,
  DocumentImg,
  UploadImageContainer,
  StyledPrimaryButton,
} from '../UserDocument/UserDocument.styles';
import cnhFrente from '../../assets/images/cnh-frente.png';
import cnhVerso from '../../assets/images/cnh-verso.png';
import rgFrente from '../../assets/images/rg-frente.png';
import rgVerso from '../../assets/images/rg-verso.png';
import { setFlow, incrementStage, changeDocumentType } from '../../ducks/stageState';
import { uploadDocument } from '../../ducks/uploadTriggers';
import { UPLOAD_FLOW, RG_RNE_DOCUMENT, CNH_DOCUMENT } from '../../utils/constants';
import Cam from '../Cam';

const DOCUMENT_SIDE = {
  [`${CNH_DOCUMENT}-front`]: {
    viewTitle: 'Foto da frente da CNH',
    viewSubTitle: 'Enquadre sua CNH no espaço abaixo:',
    previewTitle: 'As informações estão legíveis?',
    previewSubTitle: 'Lembre-se que nós também precisamos conseguir lê-las.',
    uploadMessage: 'Se preferir enviar uma foto da frente da CNH do seu computador',
    label: 'frente',
    image: cnhFrente,
    button: 'Começar',
    subTitle: (
      <>
        Precisamos de uma fotografia da <DocumentSide>frente</DocumentSide> da sua CNH.
      </>
    ),
    done: sender => sender(CNH_DOCUMENT, 'front'),
  },
  [`${CNH_DOCUMENT}-back`]: {
    viewTitle: 'Foto do verso da CNH',
    viewSubTitle: 'Enquadre sua CNH no espaço abaixo:',
    previewTitle: 'As informações estão legíveis?',
    previewSubTitle: 'Lembre-se que nós também precisamos conseguir lê-las.',
    uploadMessage: 'Se preferir enviar uma foto do verso da CNH do seu computador',
    label: 'verso',
    image: cnhVerso,
    button: 'Continuar',
    subTitle: (
      <>
        Agora, precisamos de uma fotografia do <DocumentSide>verso</DocumentSide> da sua CNH.
      </>
    ),
    done: sender => sender(CNH_DOCUMENT, 'back'),
  },
  [`${RG_RNE_DOCUMENT}-front`]: {
    viewTitle: 'Foto da frente do documento',
    viewSubTitle: 'Enquadre seu documento no espaço abaixo:',
    previewTitle: 'As informações estão legíveis?',
    previewSubTitle: 'Lembre-se que nós também precisamos conseguir lê-las.',
    uploadMessage: 'Se preferir enviar uma foto da frente do RG ou RNE do seu computador',
    label: 'frente',
    image: rgFrente,
    button: 'Começar',
    subTitle: (
      <>
        Não tem CNH? Sem problemas, envie uma foto da <DocumentSide>frente</DocumentSide> do seu RG
        ou RNE.
      </>
    ),
    done: sender => sender(RG_RNE_DOCUMENT, 'front'),
  },
  [`${RG_RNE_DOCUMENT}-back`]: {
    viewTitle: 'Foto do verso do documento',
    viewSubTitle: 'Enquadre seu documento no espaço abaixo:',
    previewTitle: 'As informações estão legíveis?',
    previewSubTitle: 'Lembre-se que nós também precisamos conseguir lê-las.',
    uploadMessage: 'Se preferir enviar uma foto do verso do RG ou RNE do seu computador',
    label: 'verso',
    image: rgVerso,
    button: 'Continuar',
    subTitle: (
      <>
        Agora, precisamos de uma fotografia do <DocumentSide>verso</DocumentSide> da seu documento.
      </>
    ),
    done: sender => sender(RG_RNE_DOCUMENT, 'back'),
  },
};

const checkForWebCamAuthorization = (changeFlow, setCamView) => () => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        setCamView(true);
      })
      .catch(() => {
        changeFlow(UPLOAD_FLOW);
      });
  }
};

const createSender = (sender, token, identificationId, callback) => (type, side) => file => {
  sender(
    {
      file,
      identificationId,
      token,
      documentKind: `${type}_${side}`,
    },
    callback,
  );
};

const uploadDone = (documentSide, documentSetter, camSetter, changeView) => () => {
  if (documentSide === 'front') {
    documentSetter('back');
    camSetter(false);
  } else {
    changeView();
  }
};

const handleTypeChange = setter => () => {
  setter(RG_RNE_DOCUMENT);
};

export const UserDocumentCam = ({
  token,
  identificationId,
  uploadFile,
  goToNextStage,
  changeFlow,
  documentType,
  setDocumentType,
}) => {
  const [camView, setCamView] = React.useState(false);
  const [documentSide, setDocumentSide] = React.useState('front');
  const sender = createSender(
    uploadFile,
    token,
    identificationId,
    uploadDone(documentSide, setDocumentSide, setCamView, goToNextStage),
  );
  const document = `${documentType}-${documentSide}`;
  if (camView) {
    return (
      <Cam
        title={DOCUMENT_SIDE[document].viewTitle}
        subTitle={DOCUMENT_SIDE[document].viewSubTitle}
        previewTitle={DOCUMENT_SIDE[document].previewTitle}
        previewSubTitle={DOCUMENT_SIDE[document].previewSubTitle}
        uploadMessage={DOCUMENT_SIDE[document].uploadMessage}
        onDone={DOCUMENT_SIDE[document].done(sender)}
      />
    );
  }
  return (
    <Wrapper>
      <HeaderMessages
        title="Documento"
        subTitle={DOCUMENT_SIDE[document].subTitle}
        details="Se possível, a foto deve ser tirada sem o plástico."
      />
      <UploadImageContainer>
        <DocumentImg src={DOCUMENT_SIDE[document].image} alt="" />

        <StyledPrimaryButton
          version={2}
          onClick={checkForWebCamAuthorization(changeFlow, setCamView)}
        >
          {DOCUMENT_SIDE[document].button}
        </StyledPrimaryButton>
      </UploadImageContainer>
      {document === 'cnh-front' ? (
        <Footer>
          <FooterMessage>
            Não possui CNH?{' '}
            <FooterLink onClick={handleTypeChange(setDocumentType)}>Clique aqui</FooterLink>.
          </FooterMessage>
        </Footer>
      ) : null}
    </Wrapper>
  );
};

UserDocumentCam.propTypes = {
  changeFlow: PropTypes.func,
  token: PropTypes.string.isRequired,
  identificationId: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  goToNextStage: PropTypes.func,
  uploadFile: PropTypes.func,
  setDocumentType: PropTypes.func,
};

UserDocumentCam.defaultProps = {
  changeFlow: () => {},
  uploadFile: () => {},
  goToNextStage: () => {},
  setDocumentType: () => {},
};

const mapDispatchToProps = {
  changeFlow: setFlow,
  uploadFile: uploadDocument,
  goToNextStage: incrementStage,
  setDocumentType: changeDocumentType,
};

const mapStateToProps = ({
  identification: { identificationId, token },
  stage: { documentType },
}) => ({
  identificationId,
  token,
  documentType,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserDocumentCam);
