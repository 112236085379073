/* eslint-disable class-methods-use-this */
/* global chrome */

export const VERIFY_INSTALLATION = 'VERIFY_INSTALLATION';
export const RESET_PIN_REQUESTED = 'RESET_PIN_REQUESTED';
export const CREATE_PIN = 'CREATE_PIN';
export const RESET_PIN_SUCCESSFUL = 'RESET_PIN_SUCCESSFUL';
export const GET_EXTENSION_STATE = 'GET_EXTENSION_STATE';

class Extension {
  sendMessage(message = {}, onResponse = () => {}) {
    return new Promise(resolve => {
      chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, message, null, action => {
        resolve(onResponse(action));
      });
    });
  }

  notifyPinCreation(pin, onResponse) {
    return this.sendMessage({ type: CREATE_PIN, payload: { pin } }, onResponse);
  }

  notifyPinReset() {
    return this.sendMessage({ type: RESET_PIN_SUCCESSFUL });
  }

  notifyPinResetRequest(resetId) {
    return this.sendMessage({
      type: RESET_PIN_REQUESTED,
      payload: { resetId },
    });
  }

  checkExtensionStatus() {
    const onResponse = action => {
      if (action) {
        const { response } = action;
        return response;
      }
      return 'not-installed';
    };

    return this.sendMessage({ type: GET_EXTENSION_STATE }, onResponse);
  }

  checkInstallation() {
    const onResponse = action => {
      if (action) {
        const {
          response: { status, versionId },
        } = action;
        return [status === 'installed', versionId];
      }
      return [false, ''];
    };

    return this.sendMessage({ type: VERIFY_INSTALLATION }, onResponse);
  }
}

export default new Extension();
