import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { TooltipBox } from 'liber-components';
import { Bar, MdHelpCircleStyled, Logo } from './NavBar.styles';
import logo from '../../assets/images/logo-liber.png';
import HelpTooltip from '../HelpTooltip/HelpTooltip';
import StepperContainer from '../Stepper/StepperContainer';
import PendenciesStepper from '../Stepper/PendenciesStepper';

export const NavBar = ({ showStepper, pendencies }) => {
  let stepper;

  if (pendencies.length > 0 && pendencies.includes('financial_data')) {
    stepper = <PendenciesStepper />;
  } else if (showStepper) {
    stepper = <StepperContainer />;
  } else {
    stepper = null;
  }

  return (
    <Bar>
      <Logo src={logo} alt="" />
      {stepper}
      <TooltipBox fixed mount="left" contentPosition="start" content={<HelpTooltip />}>
        <div>
          <MdHelpCircleStyled />
        </div>
      </TooltipBox>
    </Bar>
  );
};

NavBar.propTypes = {
  showStepper: PropTypes.bool.isRequired,
  pendencies: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ pendencies: { pendencies } }) => ({ pendencies });

export default connect(mapStateToProps)(NavBar);
