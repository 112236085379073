import api from '../api/api';
import { setLoading } from './loadingState';
import { getInitialState, saveCookieState } from '../utils/CookieHelper';
import { handleError } from '../utils/ErrorHelper';
import { REVOCATION_COOKIE } from '../utils/constants';

/** actions */

export const SET_STAGE = 'app/revogation/SET_STAGE';
export const REVOKE = 'app/revogation/REVOKE';
export const SET_REVOCATION = 'app/revogation/SET_REVOCATION';

/** stages */

export const INITIAL = 'initial';
export const PIN = 'pin';
export const IN_REVIEW = 'in_review';
export const APPROVED = 'approved';

const initialState = {
  stage: INITIAL,
  hasRevoked: false,
};

export default function reducer(
  state = getInitialState(REVOCATION_COOKIE, initialState),
  action = {},
) {
  const { type, payload } = action;
  let finalState = state;
  switch (type) {
    case SET_STAGE:
      finalState = { ...state, ...payload };
      saveCookieState(REVOCATION_COOKIE, finalState);
      return finalState;
    case SET_REVOCATION:
      finalState = { ...state, stage: INITIAL, hasRevoked: payload.hasRevoked };
      saveCookieState(REVOCATION_COOKIE, finalState);
      return finalState;

    default:
      return state;
  }
}

export function setStage(stage) {
  return { type: SET_STAGE, payload: { stage } };
}
export function setRevocation(hasRevoked = true) {
  return { type: SET_REVOCATION, payload: { hasRevoked } };
}

export function revokeDevice(pin, cpf) {
  return dispatch => {
    dispatch({ type: REVOKE });
    dispatch(setLoading(true));

    return api
      .revokeAnotherDevice(pin, cpf)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          dispatch(setStage(APPROVED));
        } else if (status === 202) {
          dispatch(setStage(IN_REVIEW));
        }
      })
      .catch(handleError)
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}
