import styled from 'styled-components';
import { PrimaryButton } from 'liber-components';

export const ContentForm = styled.form`
  display: flex;
  align-items: center;
  padding: 56px 32px 48px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 10px;
  margin-left: 8px;
  width: 142px;
`;
