import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PinForm from '../PinForm/PinForm';
import { createPin } from '../../ducks/createPinTrigger';
import withExtensionState from '../../utils/withExtensionState';
import { changePage, HOME } from '../../ducks/pageState';

export const PinCreation = ({ finishPin, extensionState, redirect }) => {
  if (extensionState !== 'approved') {
    redirect(HOME);
  } else {
    return <PinForm title="Criar PIN" onFinish={finishPin} />;
  }
};

PinCreation.propTypes = {
  finishPin: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  extensionState: PropTypes.string.isRequired,
};

export default compose(
  connect(
    null,
    { finishPin: createPin, redirect: changePage },
  ),
  withExtensionState(),
)(PinCreation);
