import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Wrapper, ImgLocation, StyledPrimaryButton } from './Location.styles';
import location from '../../assets/images/location.png';
import HeaderMessages from '../HeaderMessages/HeaderMessages';
import { uploadLocation, sendFinish } from '../../ducks/locationTriggers';
import { incrementStage } from '../../ducks/stageState';

export class Location extends PureComponent {
  handleLocationSuccess = position => {
    const { sendLocation, goToNextStage, token, identificationId } = this.props;
    sendLocation(
      {
        position,
        token,
        identificationId,
      },
      goToNextStage,
    );
  };
  handleLocationFail = () => {
    const {
      goToNextStage,
      finishRegistration,
      identificationId,
      token,
    } = this.props;
    finishRegistration({ identificationId, token }, goToNextStage);
  };

  handleAllowClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.handleLocationSuccess,
        this.handleLocationFail,
      );
    } else {
      this.handleLocationFail();
    }
  };

  render() {
    return (
      <Wrapper>
        <HeaderMessages
          title="Autorização de acesso"
          details={
            <>
              Por motivos de segurança, precisamos que você autorize o acesso
              <br />à sua localização para concluirmos a habilitação do seu
              dispositivo.
            </>
          }
        />
        <ImgLocation src={location} alt="Localização" />
        <StyledPrimaryButton version={2} onClick={this.handleAllowClick}>
          Autorizar
        </StyledPrimaryButton>
      </Wrapper>
    );
  }
}

Location.propTypes = {
  sendLocation: PropTypes.func.isRequired,
  goToNextStage: PropTypes.func.isRequired,
  finishRegistration: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  identificationId: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  sendLocation: uploadLocation,
  goToNextStage: incrementStage,
  finishRegistration: sendFinish,
};

const mapStateToProps = ({ identification: { identificationId, token } }) => ({
  identificationId,
  token,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Location);
