import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import selfie from '../../../assets/images/selfie.png';
import HeaderMessages from '../../HeaderMessages/HeaderMessages';
import {
  BlueBold,
  SelfieImg,
  UploadContainer,
  StyledPrimaryButton,
  ImageTip,
  Wrapper,
} from './PinSelfie.styles';
import UploadFile from '../../UploadFile/UploadFile';
import { uploadSelfie } from '../../../ducks/pinResetState';

const resetFileState = setFile => () => setFile({});

const sendFile = (properties, file) => () => {
  const { cpf, uploadFile, hasExtension } = properties;
  uploadFile(cpf, file, hasExtension);
};

export const PinSelfie = props => {
  const [file, setFile] = useState({});
  const hasUploadedFile = file instanceof File;

  return (
    <Wrapper>
      <HeaderMessages
        title="Solicitar redefinição de PIN"
        details={
          <>
            Por motivos de segurança, precisamos de uma{' '}
            <BlueBold>foto sua segurando seu documento</BlueBold> <br /> para redefinir seu PIN.
            Utilize o mesmo documento enviado no cadastro (CNH, <br /> RG ou RNE), mostrando o lado
            com a foto. Lembre-se: não coloque o documento <br /> na frente do rosto, ambos devem
            aparecer nitidamente na fotografia.
          </>
        }
      />
      <UploadContainer>
        <SelfieImg src={selfie} alt="Selfie!" />
        <UploadFile onChange={setFile} uploadedFile={file} onRemoveFile={resetFileState(setFile)}>
          Selecione um arquivo do seu computador
        </UploadFile>
        <ImageTip>*Formatos permitidos: PNG ou JPG</ImageTip>
        <StyledPrimaryButton
          version={2}
          onClick={sendFile(props, file)}
          disabled={!hasUploadedFile}
        >
          Enviar arquivo
        </StyledPrimaryButton>
      </UploadContainer>
    </Wrapper>
  );
};

PinSelfie.propTypes = {
  cpf: PropTypes.string.isRequired,
  uploadFile: PropTypes.func.isRequired,
  hasExtension: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ pinReset: { cpf } }) => ({ cpf });

export default connect(
  mapStateToProps,
  { uploadFile: uploadSelfie },
)(PinSelfie);
