import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import FormTemplate from '../../../FormTemplate/FormTemplate';
import { BlueBold, TextField, CardContent, PrimaryButton } from '../Pendencies.styles';
import { isValueEmpty, formatToNumber } from '../../../../utils/MoneyHelper';
import { saveIncome } from '../../../../ducks/pendenciesState';

export const Income = ({ onConfirm }) => {
  const [income, setIncome] = useState('');

  return (
    <FormTemplate
      title="Renda"
      subTitle={
        <>
          Qual é o total da sua <BlueBold>renda mensal</BlueBold>?
        </>
      }
      details={
        <>
          Precisamos pedir essa informação para <br /> atender à regulamentação
        </>
      }
    >
      <form
        id="income-form"
        onSubmit={event => {
          event.preventDefault();
          onConfirm(String(formatToNumber(income)));
        }}
      >
        <CardContent>
          <TextField value={income} onChange={setIncome} label="Renda mensal" />
          <PrimaryButton disabled={isValueEmpty(income)} type="submit">
            Continuar
          </PrimaryButton>
        </CardContent>
      </form>
    </FormTemplate>
  );
};

Income.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onConfirm: saveIncome,
};

export default connect(
  null,
  mapDispatchToProps,
)(Income);
