import { getInitialState, saveCookieState, removeAllCookies } from '../utils/CookieHelper';
import {
  COOKIE_STAGE_ID,
  INCREMENT_STAGE,
  DECREMENT_STAGE,
  SET_FLOW,
  WEBCAM_FLOW,
  STEPPER_VIEW,
  CHANGE_DOCUMENT_TYPE,
  CNH_DOCUMENT,
} from '../utils/constants';

const initialState = {
  stage: 0,
  flow: WEBCAM_FLOW,
  documentType: CNH_DOCUMENT,
  stepper: true,
};

export default function reducer(
  state = getInitialState(COOKIE_STAGE_ID, initialState),
  action = {},
) {
  const { type } = action;
  switch (type) {
    case CHANGE_DOCUMENT_TYPE: {
      const { payload: documentType } = action;
      return {
        ...state,
        documentType,
      };
    }
    case STEPPER_VIEW: {
      const { payload: stepper } = action;
      return { ...state, stepper };
    }
    case SET_FLOW: {
      const { payload: flow } = action;
      const nextState = { ...state, flow };
      saveCookieState(COOKIE_STAGE_ID, nextState);
      return nextState;
    }
    case INCREMENT_STAGE: {
      const nextStage = state.stage + 1;
      const nextState = {
        ...state,
        stage: nextStage,
      };

      saveCookieState(COOKIE_STAGE_ID, nextState);

      if (nextStage === 5) {
        removeAllCookies();
        window.onbeforeunload = () => {};
      }

      return nextState;
    }
    case DECREMENT_STAGE: {
      const nextState = {
        ...state,
        stage: state.stage - 1,
      };
      saveCookieState(COOKIE_STAGE_ID, nextState);
      return nextState;
    }
    default:
      return state;
  }
}

export function incrementStage() {
  return { type: INCREMENT_STAGE };
}

export function decrementStage() {
  return { type: DECREMENT_STAGE };
}

export function setFlow(payload) {
  return { type: SET_FLOW, payload };
}

export function changeStepperView(payload) {
  return { type: STEPPER_VIEW, payload };
}

export function changeDocumentType(type) {
  return { type: CHANGE_DOCUMENT_TYPE, payload: type };
}
