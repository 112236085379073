export const SET_LOADING = 'liber-id/loading/SET_LOADING';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return { ...state, ...payload };

    default:
      return state;
  }
}

export function setLoading(loading) {
  return { type: SET_LOADING, payload: { loading } };
}
