import styled from 'styled-components';
import { PrimaryButton, CardFooter } from 'liber-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 32px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 10px;
  margin-left: 8px;
`;

export const StyledCardFooter = styled(CardFooter)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
