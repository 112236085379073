import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField, TooltipBox } from 'liber-components';
import {
  StyledPrimaryButton,
  StyledTextField,
  Container,
  FieldInfo,
  RequiredFields,
  StyledButton,
  SocialNameContainer,
  StyledMdHelpCircle,
  TooltipContainer,
  TooltipTitle,
  TooltipText,
  IconContainer,
  InputContainer,
} from './UserContact.styles';
import FormTemplate from '../FormTemplate/FormTemplate';
import { uploadContact } from '../../ducks/uploadTriggers';
import { incrementStage } from '../../ducks/stageState';

const LABELS = {
  name: 'Nome Completo*',
  socialName: 'Nome Social',
  email: 'E-mail*',
  phone: 'Telefone*',
};

const initialState = {
  name: '',
  socialName: '',
  email: '',
  phone: '',
  showSocialName: false,
  error: {},
};

export class UserContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  onChangeField = field => value => {
    if (field === 'email') {
      if (!this.isEmailValid(value) && value !== '') {
        this.setState({ [field]: value, error: { email: 'Formato inválido' } });
      } else {
        this.setState({ [field]: value, error: {} });
      }
    } else {
      this.setState({ [field]: value });
    }
  };

  isPhone = field => field === 'phone';

  isName = field => field === 'name';

  isFieldValid = content => content.length > 0;

  onShowSocialName = () => this.setState({ showSocialName: true });

  areFieldsValid = () => {
    const { name, email, phone } = this.state;

    const stringFields = { name, email, phone };
    return Object.keys(stringFields).every(field => {
      if (field === 'email') {
        return this.isEmailValid(stringFields[field]);
      }
      return this.isFieldValid(stringFields[field]);
    });
  };

  resetAndGoToNextStage = () => {
    const { goToNextStage } = this.props;
    this.setState({ ...initialState });
    goToNextStage();
  };

  formatPhone = phone =>
    phone
      .split('-')
      .join('')
      .split('(')
      .join('')
      .split(')')
      .join('');

  confirmInformation = event => {
    const { identificationId, token, sendContacts } = this.props;
    const { name, phone, email, socialName, showSocialName } = this.state;
    event.preventDefault();

    const userContact = {
      name,
      email,
      phone: this.formatPhone(phone),
    };

    if (showSocialName) {
      userContact.socialName = socialName;
    }

    sendContacts({ identificationId, token, userContact }, this.resetAndGoToNextStage);
  };

  isEmailValid = email => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return regex.test(email);
  };

  renderTextField = (field, TextComponent = StyledTextField) => {
    const { error } = this.state;
    const isPhone = this.isPhone(field);
    const isName = this.isName(field);
    return (
      <InputContainer key={field} margin={Boolean(error[field]) || isName}>
        <TextComponent
          version={2}
          id={`${field}-input`}
          width="346px"
          value={this.state[field]}
          onChange={this.onChangeField(field)}
          label={LABELS[field]}
          mask={isPhone ? '(99)99999-9999' : null}
          errorMessage={error[field] || ''}
          maskChar={null}
        />
      </InputContainer>
    );
  };

  renderSocialName = () => {
    const { showSocialName } = this.state;

    return showSocialName ? (
      <>
        {this.renderTextField('socialName', TextField)}
        <FieldInfo>Como devemos te chamar</FieldInfo>
      </>
    ) : (
      <SocialNameContainer>
        <StyledButton type="button" version={2} onClick={this.onShowSocialName}>
          + Adicionar Nome Social
        </StyledButton>
        <TooltipBox fixed mount="top" contentPosition="start" content={this.renderTooltipContent()}>
          <IconContainer>
            <StyledMdHelpCircle />
          </IconContainer>
        </TooltipBox>
      </SocialNameContainer>
    );
  };

  renderTooltipContent = () => (
    <TooltipContainer>
      <TooltipTitle>Prefere utilizar outro nome?</TooltipTitle>
      <TooltipText>
        Caso prefira utilizar seu nome social <br /> pedimos que nos informe como devemos <br /> nos
        referir a você de agora em diante.
      </TooltipText>
    </TooltipContainer>
  );

  render() {
    return (
      <FormTemplate
        title="Contato"
        details={
          <>
            Enquanto analisamos as fotos enviadas,
            <br />
            queremos te conhecer um pouco melhor.
          </>
        }
        alignCenter={false}
      >
        <form id="contact-form" onSubmit={this.confirmInformation}>
          <Container>
            {this.renderTextField('name', TextField)}
            <FieldInfo>Conforme consta em seu documento</FieldInfo>
            {this.renderSocialName()}
            {this.renderTextField('email')}
            {this.renderTextField('phone')}
            <RequiredFields>*Campos obrigatórios</RequiredFields>
            <StyledPrimaryButton disabled={!this.areFieldsValid()} type="submit">
              Continuar
            </StyledPrimaryButton>
          </Container>
        </form>
      </FormTemplate>
    );
  }
}

const mapStateToProps = ({ identification: { identificationId, token } }) => ({
  identificationId,
  token,
});

const mapDispatchToProps = {
  sendContacts: uploadContact,
  goToNextStage: incrementStage,
};

UserContact.propTypes = {
  sendContacts: PropTypes.func.isRequired,
  identificationId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  goToNextStage: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserContact);
