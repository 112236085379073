import { toast } from 'liber-components';
import extension from '../extension';
import { handleError } from '../utils/ErrorHelper';
import { setLoading } from './loadingState';

export const CREATE_PIN = 'liber-id/identification/CREATE_PIN';

export function createPin(pin) {
  return dispatch => {
    dispatch({ type: CREATE_PIN });
    dispatch(setLoading(true));

    const onCreationResponse = action => {
      const {
        response: { status, message, redirectTo },
      } = action;
      dispatch(setLoading(false));
      if (status) {
        window.location.replace(redirectTo);
      } else {
        toast({ message: 'Erro', info: message }, 'error', 5000);
      }
    };

    return extension.notifyPinCreation(pin, onCreationResponse).catch(handleError);
  };
}
