import PropTypes from 'prop-types';

import api from '../api/api';
import { handleError } from '../utils/ErrorHelper';
import { setLoading } from './loadingState';

export const addressShape = PropTypes.shape({
  zipCode: PropTypes.string,
  street: PropTypes.string,
  number: PropTypes.string,
  complement: PropTypes.string,
  district: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
});

export const SET_ADDRESS = 'liber-id/address/SET_ADDRESS';
export const GET_ADDRESS = 'liber-id/address/GET_ADDRESS';
export const SEND_ADDRESS = 'liber-id/address/SEND_ADDRESS';
export const SET_ADDRESS_STAGE = 'liber-id/address/SET_ADDRESS_STAGE';

export const RESIDENTIAL = 'residential';
export const BUSINESS = 'business';
export const BUSINESS_CONFIRMATION = 'business-confimation';

const initialState = {
  addressStage: RESIDENTIAL,
  residential: {
    hasFetched: false,
    address: {
      zipCode: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      state: '',
      city: '',
    },
  },
  business: {
    hasFetched: false,
    address: {
      zipCode: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      state: '',
      city: '',
    },
  },
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_ADDRESS: {
      const { addressType, address } = payload;
      return {
        ...state,
        [addressType]: {
          hasFetched: true,
          address: { ...state[addressType].address, ...address },
        },
      };
    }
    case SET_ADDRESS_STAGE:
      return { ...state, ...payload };

    default:
      return state;
  }
}

export function setAddress(addressType, address) {
  return { type: SET_ADDRESS, payload: { address, addressType } };
}
export function setAddressStage(addressStage) {
  return { type: SET_ADDRESS_STAGE, payload: { addressStage } };
}

export function getAddress(addressType, zipCode) {
  return dispatch => {
    dispatch({ type: GET_ADDRESS });
    dispatch(setLoading(true));

    return api
      .getAddressByZipCode(zipCode)
      .then(response => {
        const {
          data: {
            address: { complement: number, ...address },
          },
        } = response;
        dispatch(setAddress(addressType, { ...address, number, zipCode }));
      })
      .catch(handleError)
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function sendAddress({ identificationId, token, address, addressType }, onAddressSent) {
  return dispatch => {
    dispatch({ type: SEND_ADDRESS });
    dispatch(setLoading(true));
    let data = null;
    if (address) {
      const { zipCode: zipcode, ...rest } = address;
      data = { ...rest, zipcode };
    }

    const kind = addressType === RESIDENTIAL ? 'home_address' : `${BUSINESS}_address`;

    const requestData = {
      kind,
      data,
    };

    return api
      .sendDocument(identificationId, token, requestData)
      .then(() => {
        if (onAddressSent) {
          onAddressSent();
        }
        dispatch(setAddress(addressType, address));
      })
      .catch(handleError)
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}
