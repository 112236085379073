import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { PrimaryButton, MdCloseCircle, MdCheckCircle } from 'liber-components';

export const SliderContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const handleSliderActive = (active = 0) => `-${active * 100}%`;

export const Slider = styled.div`
  display: flex;
  transition: margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  will-change: margin-left;
  margin-left: ${props => handleSliderActive(props.active)};
  flex-direction: row;
  width: 100%;

  & > * {
    width: 100% !important;
    flex-shrink: 0 !important;
  }
`;

export const ExampleContainer = styled.div`
  margin-top: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Button = styled(PrimaryButton).attrs(() => ({ version: '2' }))`
  width: 256px;
`;

export const DotContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
`;

const handleDotActive = ({ active }) => {
  return active
    ? css`
        transform: rotateY(180deg);
      `
    : css`
        transform: rotateY(0deg);
      `;
};

const innerDot = css`
  content: '';
  position: absolute;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  backface-visibility: hidden;
  top: 0px;
  left: 0px;
  cursor: pointer;
`;

export const Dot = styled.div`
  --size: 8px;
  background-color: transparent;
  transform-style: preserve-3d;
  transition: transform 450ms ease, opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  margin: 0 4px;
  width: var(--size);
  height: var(--size);
  position: relative;

  ${handleDotActive}

  &:before {
    background-color: #4cbaff;
    transform: rotateY(180deg);
    ${innerDot}
  }

  &:after {
    background-color: #e4e6e8;
    ${innerDot}
  }
`;

export const SliderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const SliderText = styled.div`
  width: 100%;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #798e9b;
  margin-top: 16px;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const ImgAlt = styled.div`
  display: block;
  background-color: #3a5566;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const ImgContainer = styled.div`
  position: relative;
  margin: 0 8px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const icon = css`
  --size: 32px;
  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);
  position: absolute;
  top: 18px;
  right: 18px;
`;
const RightIcon = styled(MdCheckCircle)`
  fill: #13ce66;
  ${icon}
`;

const WrongIcon = styled(MdCloseCircle)`
  fill: #dc3545;
  ${icon}
`;

export const Image = ({ type, width, height, ...props }) => {
  const typeObj = {
    wrong: <WrongIcon />,
    right: <RightIcon />,
  };
  return (
    <ImgContainer width={width} height={height}>
      <ImgAlt />
      <Img {...props} alt="" />
      {typeObj[type] || null}
    </ImgContainer>
  );
};

Image.propTypes = {
  type: PropTypes.oneOf(['right', 'wrong', null]),
  width: PropTypes.number,
  height: PropTypes.number,
};

Image.defaultProps = {
  type: 'wrong',
  width: 200,
  height: 200,
};
