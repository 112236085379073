import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Img = styled.img`
  width: 235px;
  margin: 97px 0px 42px 0px;
`;
