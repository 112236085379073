import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ACCOUNT_STEP,
  INCOME_STEP,
  ACCOUNT_TYPE,
  ACCOUNT_INFO,
  PATRIMONY_STEP,
  DATA_SENT,
} from '../../utils/constants';
import AccountType from './components/AccountType/AccountType';
import AccountInfo from './components/AccountInfo/AccountInfo';
import Income from './components/Income/Income';
import Patrimony from './components/Patrimony/Patrimony';
import DataSent from './components/DataSent/DataSent';
import { mapJwtToState } from '../../ducks/pendenciesState';

const reference = {
  [ACCOUNT_STEP]: {
    [ACCOUNT_TYPE]: AccountType,
    [ACCOUNT_INFO]: AccountInfo,
  },
  [INCOME_STEP]: Income,
  [PATRIMONY_STEP]: Patrimony,
  [DATA_SENT]: DataSent,
};

export const PendenciesManager = ({ step, view, setUpJwtInformation }) => {
  React.useEffect(() => {
    setUpJwtInformation();
  }, [setUpJwtInformation]);

  let View;
  if (step !== ACCOUNT_STEP) {
    View = reference[step];
  } else {
    View = reference[step][view];
  }

  return <View />;
};

PendenciesManager.propTypes = {
  step: PropTypes.oneOf([ACCOUNT_STEP, INCOME_STEP, PATRIMONY_STEP, DATA_SENT]).isRequired,
  view: PropTypes.oneOf([ACCOUNT_TYPE, ACCOUNT_INFO]).isRequired,
  setUpJwtInformation: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pendencies: { step, view } }) => ({ step, view });

const mapDispatchToProps = {
  setUpJwtInformation: mapJwtToState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendenciesManager);
