import { PrimaryButton } from 'liber-components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImgLocation = styled.img`
  width: 176.7px;
  border-radius: 8px;
  margin: 80px 0px;
`;
export const ImgSupport = styled.img`
  width: 238.9px;
  border-radius: 8px;
  margin: 48px 0px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  width: 256px;
`;

export const ContactsContainer = styled.div`
  margin-bottom: 64px;
`;
