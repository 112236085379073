import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HeaderMessages from '../HeaderMessages/HeaderMessages';
import {
  Wrapper,
  DocumentSide,
  DocumentImg,
  UploadImageContainer,
  ImageHelper,
  StyledPrimaryButton,
  Footer,
  Image,
  PdfContainer,
} from './UserDocument.styles';

import { Button, BackButton, ButtonContainer, PreviewContainer } from '../Cam/Cam.styles';
import { useUpload } from '../../utils/useCam';
import { FooterMessage, FooterLink } from '../Base.styles';
import UploadFile from '../UploadFile/UploadFile';
import cnhFrente from '../../assets/images/cnh-frente.png';
import cnhVerso from '../../assets/images/cnh-verso.png';
import rgFrente from '../../assets/images/rg-frente.png';
import rgVerso from '../../assets/images/rg-verso.png';
import { uploadDocument } from '../../ducks/uploadTriggers';
import { incrementStage, changeDocumentType } from '../../ducks/stageState';
import { RG_RNE_DOCUMENT, CNH_DOCUMENT } from '../../utils/constants';

const DOCUMENT_SIDE = {
  [`${CNH_DOCUMENT}-front`]: {
    label: 'frente',
    image: cnhFrente,
    subTitle: (
      <>
        Precisamos de uma fotografia da <DocumentSide>frente</DocumentSide> da sua CNH.
      </>
    ),
    done: sender => sender(CNH_DOCUMENT, 'front'),
  },
  [`${CNH_DOCUMENT}-back`]: {
    label: 'verso',
    image: cnhVerso,
    subTitle: (
      <>
        Agora, precisamos de uma fotografia do <DocumentSide>verso</DocumentSide> da sua CNH.
      </>
    ),
    done: sender => sender(CNH_DOCUMENT, 'back'),
  },
  [`${RG_RNE_DOCUMENT}-front`]: {
    label: 'frente',
    image: rgFrente,
    subTitle: (
      <>
        Precisamos de uma fotografia da <DocumentSide>frente</DocumentSide> da seu documento.
      </>
    ),
    done: sender => sender(RG_RNE_DOCUMENT, 'front'),
  },
  [`${RG_RNE_DOCUMENT}-back`]: {
    label: 'verso',
    image: rgVerso,
    subTitle: (
      <>
        Agora, precisamos de uma fotografia do <DocumentSide>verso</DocumentSide> do seu documento.
      </>
    ),
    done: sender => sender(RG_RNE_DOCUMENT, 'back'),
  },
};

const handleChangeFile = (setUploadImage, setUploadPdf) => file => {
  if (file) {
    if (file.type === 'application/pdf') {
      setUploadPdf(file);
    } else {
      setUploadImage(file);
    }
  }
};

const handleShowPreview = setter => () => {
  setter(true);
};

const handleTypeChange = setter => () => {
  setter(RG_RNE_DOCUMENT);
};

const handleTryAgain = (setShowPreview, clearFile) => () => {
  setShowPreview(false);
  clearFile();
};

const createSender = (file, sender, token, identificationId, callback) => (type, side) => () => {
  sender(
    {
      file,
      identificationId,
      token,
      documentKind: `${type}_${side}`,
    },
    callback,
  );
};

const uploadDone = (documentSide, documentSetter, setShowPreview, clearFile, changeView) => () => {
  clearFile();
  if (documentSide === 'front') {
    documentSetter('back');
    setShowPreview(false);
  } else {
    changeView();
  }
};

export const UserDocument = ({
  token,
  identificationId,
  uploadFile,
  goToNextStage,
  documentType,
  setDocumentType,
}) => {
  const [documentSide, setDocumentSide] = React.useState('front');
  const {
    showPreview,
    setShowPreview,
    file,
    clearFile,
    setUploadImage,
    setUploadPdf,
    uploadImg,
    uploadPdf,
  } = useUpload();

  const sender = createSender(
    file,
    uploadFile,
    token,
    identificationId,
    uploadDone(documentSide, setDocumentSide, setShowPreview, clearFile, goToNextStage),
  );

  const document = `${documentType}-${documentSide}`;
  if (showPreview && (uploadImg || uploadPdf)) {
    return (
      <Wrapper>
        <HeaderMessages
          subTitle="As informações estão legíveis?"
          details="Lembre-se que nós também precisamos conseguir lê-las."
        />
        <PreviewContainer>
          {uploadPdf ? (
            <PdfContainer src={uploadPdf} />
          ) : (
            <Image src={uploadImg} alt="Visualização do Documento" />
          )}
        </PreviewContainer>
        <ButtonContainer>
          <BackButton onClick={handleTryAgain(setShowPreview, clearFile)}>Trocar Foto</BackButton>
          <Button version={2} onClick={DOCUMENT_SIDE[document].done(sender)} disable={!file}>
            Continuar
          </Button>
        </ButtonContainer>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <HeaderMessages
        title="Documento"
        subTitle={DOCUMENT_SIDE[document].subTitle}
        details="Se possível, a foto deve ser tirada sem o plástico."
      />
      <UploadImageContainer>
        <DocumentImg src={DOCUMENT_SIDE[document].image} alt="" />
        <UploadFile
          onChange={handleChangeFile(setUploadImage, setUploadPdf)}
          uploadedFile={file || {}}
          onRemoveFile={clearFile}
        >
          Selecione um arquivo do seu computador
        </UploadFile>
        <ImageHelper>*Formatos permitidos: PNG, JPG ou PDF</ImageHelper>
        <StyledPrimaryButton
          version={2}
          onClick={handleShowPreview(setShowPreview)}
          disabled={!file}
        >
          Continuar
        </StyledPrimaryButton>
      </UploadImageContainer>
      {document === `${CNH_DOCUMENT}-front` ? (
        <Footer>
          <FooterMessage>
            Não possui CNH?{' '}
            <FooterLink onClick={handleTypeChange(setDocumentType)}>Clique aqui</FooterLink>.
          </FooterMessage>
        </Footer>
      ) : null}
    </Wrapper>
  );
};

UserDocument.propTypes = {
  token: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  identificationId: PropTypes.string.isRequired,
  uploadFile: PropTypes.func,
  goToNextStage: PropTypes.func,
  setDocumentType: PropTypes.func,
};

UserDocument.defaultProps = {
  uploadFile: () => {},
  goToNextStage: () => {},
  setDocumentType: () => {},
};

const mapDispatchToProps = {
  uploadFile: uploadDocument,
  goToNextStage: incrementStage,
  setDocumentType: changeDocumentType,
};

const mapStateToProps = ({
  identification: { identificationId, token },
  stage: { documentType },
}) => ({
  identificationId,
  token,
  documentType,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserDocument);
