import { PrimaryButton } from 'liber-components';
import styled from 'styled-components';

export const HoldDocument = styled.span`
  color: #009dff;
`;

export const Wrapper = styled.div`
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DocumentImg = styled.img`
  width: 230px;
  border-radius: 8px;
  margin-top: 48px;
  margin-bottom: 29px;
  margin-left: 38px;
`;

export const UploadImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageHelper = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #798e9b;
  margin-top: 8px;
  margin-bottom: 29px;
  width: 100%;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`;
