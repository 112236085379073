import React from 'react';
import PropTypes from 'prop-types';
import { LoadingImage, LoadingBlock } from './Loading.styles';

const Loading = ({ loading, children }) => {
  return (
    <div id="liber_id_web_loading">
      <LoadingImage loading={loading} loop />
      <LoadingBlock loading={loading}>{children}</LoadingBlock>
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

Loading.defaultProps = {
  loading: false,
  children: null,
};

export default Loading;
