import {
  ACCOUNT_STEP,
  ACCOUNT_TYPE,
  INCOME_STEP,
  DATA_SENT,
  PATRIMONY_STEP,
} from '../utils/constants';
import { setLoading } from './loadingState';
import api from '../api/api';
import { handleError } from '../utils/ErrorHelper';
import { decodeAndFormatJWT } from '../utils/JWTHelper';
import { clearURL } from './pageState';

export const CHANGE_STEP = 'liber-id/pendencies/CHANGE_STEP';
export const UPDATE_JWT = 'liber-id/pendencies/UPDATE_JWT';
export const CHANGE_VIEW = 'liber-id/pendencies/CHANGE_VIEW';
export const SET_ACCOUNT_KIND = 'liber-id/pendencies/SET_ACCOUNT_KIND';
export const SEND_BANK_ACCOUNT = 'liber-id/pendencies/SEND_BANK_ACCOUNT';
export const SEND_BANK_ACCOUNT_SUCCESS = 'liber-id/pendencies/SEND_BANK_ACCOUNT_SUCCESS';
export const SEND_FINANCIAL_DATA = 'liber-id/pendencies/SEND_FINANCIAL_DATA';
export const MAP_JWT_TO_STATE = 'liber-id/pendencies/MAP_JWT_TO_STATE';
export const SET_INCOME = 'liber-id/pendencies/SET_INCOME';
export const FETCH_BANK_LIST = 'liber-id/pendencies/FETCH_BANK_LIST';

const initialState = {
  step: ACCOUNT_STEP,
  view: ACCOUNT_TYPE,
  idJWT: null,
  authJWT: null,
  redirectData: {
    service: '',
    targetUrl: '',
  },
  user: {
    id: '',
    name: '',
    cpf: '',
    email: '',
  },
  pendencies: [],
  income: '',
  accountKind: '',
};

const redirectToAuth = (redirectData, refreshToken = true) => {
  const urlParams = new URLSearchParams();
  const { service, targetUrl } = redirectData;

  urlParams.set('service', service);
  urlParams.set('target_url', targetUrl);
  if (refreshToken) {
    urlParams.set('refresh_token', true);
  }

  window.location.replace(`${process.env.REACT_APP_AUTH_API}/?${urlParams.toString()}`);
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_STEP:
    case CHANGE_VIEW:
    case SET_INCOME:
    case SET_ACCOUNT_KIND:
      return { ...state, ...payload };
    case UPDATE_JWT: {
      const { pendencies } = payload;
      let viewData = {};

      if (pendencies.includes('bank_account')) {
        viewData = { step: ACCOUNT_STEP, view: ACCOUNT_TYPE };
      } else if (pendencies.includes('financial_data')) {
        viewData = { step: INCOME_STEP };
      }

      return { ...state, ...payload, ...viewData };
    }
    case SEND_BANK_ACCOUNT_SUCCESS: {
      const { redirectData } = state;
      if (state.pendencies.includes('financial_data')) {
        return { ...state, step: INCOME_STEP };
      }

      redirectToAuth(redirectData);
      return state;
    }

    default:
      return state;
  }
}

export const changeStep = step => ({ type: CHANGE_STEP, payload: { step } });

export const saveIncome = income => ({
  type: SET_INCOME,
  payload: { income, step: PATRIMONY_STEP },
});

export const updateJWTs = (authJWT, idJWT, redirectData, jwtPayload) => ({
  type: UPDATE_JWT,
  payload: { idJWT, authJWT, redirectData, ...jwtPayload },
});

export const changeView = view => ({ type: CHANGE_VIEW, payload: { view } });

export const setAccountKind = accountKind => ({ type: SET_ACCOUNT_KIND, payload: { accountKind } });

export const mapJwtToState = () => dispatch => {
  dispatch(setLoading(true));
  dispatch({ type: MAP_JWT_TO_STATE });
  const urlParams = new URLSearchParams(window.location.search);
  const authJWT = urlParams.get('jwt');
  const targetUrl = urlParams.get('target_url');
  const service = urlParams.get('service');

  const redirectData = { targetUrl, service };

  if (authJWT) {
    if (process.env.NODE_ENV !== 'development') {
      dispatch(clearURL(urlParams, 'jwt', 'target_url', 'service'));
    }

    return api
      .getIdJWT(authJWT)
      .then(idJWT => {
        const jwtPayload = decodeAndFormatJWT(authJWT);
        const { pendencies } = jwtPayload;

        if (pendencies.length === 0) {
          dispatch(changeStep(DATA_SENT));
        } else {
          dispatch(updateJWTs(authJWT, idJWT, redirectData, jwtPayload));
        }
      })
      .catch(error => {
        dispatch(changeStep(DATA_SENT));
        handleError(error);
      })
      .finally(() => dispatch(setLoading(false)));
  }
  redirectToAuth(redirectData, false);
  return null;
};

export const registerBankAccount = (idJWT, bankAccount) => dispatch => {
  dispatch({ type: SEND_BANK_ACCOUNT });
  dispatch(setLoading(true));

  return api
    .sendBankAccount(idJWT, bankAccount)
    .then(() => dispatch({ type: SEND_BANK_ACCOUNT_SUCCESS }))
    .catch(handleError)
    .finally(() => dispatch(setLoading(false)));
};

export const registerFinancialData = (idJWT, financialData, redirectData) => dispatch => {
  dispatch({ type: SEND_FINANCIAL_DATA });
  dispatch(setLoading(true));

  return api
    .sendFinancialData(idJWT, financialData)
    .then(() => {
      if (redirectData.service) redirectToAuth(redirectData);
      else window.location.replace(redirectData.targetUrl);
    })
    .catch(handleError)
    .finally(() => dispatch(setLoading(false)));
};
