import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FieldName, FieldValue } from './HolderInfo.styles';

const formatCpf = cpf => {
  const formattedCPF = [
    cpf.slice(0, 3),
    '.',
    cpf.slice(3, 6),
    '.',
    cpf.slice(6, 9),
    '-',
    cpf.slice(9),
  ].join('');

  return formattedCPF;
};

export const HolderInfo = ({ user: { name, cpf } }) => {
  return (
    <>
      <FieldName>Nome Completo</FieldName>
      <FieldValue spacing>{name}</FieldValue>
      <FieldName>CPF</FieldName>
      <FieldValue>{formatCpf(cpf)}</FieldValue>
    </>
  );
};

HolderInfo.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ pendencies: { user } }) => ({ user });

export default connect(mapStateToProps)(HolderInfo);
