import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HoldDocument, Wrapper } from './SelfieCam.styles';
import HeaderMessages from '../HeaderMessages/HeaderMessages';
import { uploadDocument } from '../../ducks/uploadTriggers';
import { incrementStage } from '../../ducks/stageState';
import ExampleCarousel from './ExampleCarousel';
import Cam from '../Cam';

const handleCarouselDone = setCamView => () => {
  setCamView(true);
};

const handleUpload = (sender, token, identificationId, callback) => file => {
  sender(
    {
      file,
      identificationId,
      token,
      documentKind: 'selfies',
    },
    callback,
  );
};

export const SelfieCam = ({ uploadFile, token, identificationId, goToNextStage }) => {
  const [camView, setCamView] = React.useState(false);
  if (camView) {
    return (
      <Cam
        title="Enquadre seu rosto e o documento no espaço abaixo"
        subTitle="Lembre-se: seu rosto e o documento devem aparecer nitidamente na fotografia."
        previewTitle="Seu rosto e o documento estão nítidos?"
        previewSubTitle="Lembre-se que nós precisamos ver seu rosto e identificar o documento"
        accept={['image/png', 'image/jpeg']}
        acceptMessage="*Formatos permitidos: PNG e JPG"
        uploadMessage="Se preferir enviar uma foto sua com o documento do seu computador"
        onDone={handleUpload(uploadFile, token, identificationId, goToNextStage)}
      />
    );
  }
  return (
    <Wrapper>
      <HeaderMessages
        title="Fotografia"
        subTitle={
          <>
            Agora, precisamos de uma <HoldDocument>foto sua segurando seu documento.</HoldDocument>
          </>
        }
        details={
          <>
            Utilize o mesmo documento enviado na etapa anterior. A etapa atual
            <br />é importante para compararmos sua foto com a do documento.
          </>
        }
      />
      <ExampleCarousel onDone={handleCarouselDone(setCamView)} />
    </Wrapper>
  );
};

SelfieCam.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  goToNextStage: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  identificationId: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  uploadFile: uploadDocument,
  goToNextStage: incrementStage,
};

const mapStateToProps = ({ identification: { identificationId, token } }) => ({
  identificationId,
  token,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelfieCam);
