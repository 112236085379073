import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Container,
  TextFieldStyled,
  RequiredLabel,
  PrimaryButtonStyled
} from './AddressForm.styles';
import {
  addressShape,
  sendAddress,
  RESIDENTIAL,
  setAddressStage,
  BUSINESS_CONFIRMATION
} from '../../ducks/addressState';
import { incrementStage } from '../../ducks/stageState';

const LABELS = {
  street: 'Rua/Avenida*',
  number: 'Número*',
  complement: 'Complemento',
  district: 'Bairro*',
  city: 'Cidade*',
  state: 'Estado*'
};

export class AddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.address
    };
  }

  onAddressSent = () => {
    const { addressType, goToAddressStage, goToNextStage } = this.props;
    if (addressType === RESIDENTIAL) {
      goToAddressStage(BUSINESS_CONFIRMATION);
    } else {
      goToNextStage();
    }
  };

  isValid = field => this.state[field].length > 0;

  areFieldsValid = () => {
    const requiredFields = Object.keys(this.state).filter(
      field => field !== 'complement'
    );

    let valid = true;

    requiredFields.forEach(field => {
      if (!this.isValid(field)) {
        valid = false;
      }
    });

    return valid;
  };

  submitAddress = event => {
    const {
      sendAddressDocument,
      identificationId,
      token,
      addressType
    } = this.props;

    event.preventDefault();

    sendAddressDocument(
      {
        identificationId,
        token,
        addressType,
        address: { ...this.state }
      },
      this.onAddressSent
    );
  };

  changeField = field => value => this.setState({ [field]: value });

  render() {
    const { address } = this.props;
    return (
      <form id="address-form" onSubmit={this.submitAddress}>
        <Container>
          {Object.keys(address)
            .filter(field => field !== 'zipCode')
            .map(field => (
              <TextFieldStyled
                key={field}
                width="346px"
                label={LABELS[field]}
                version={2}
                value={this.state[field]}
                onChange={this.changeField(field)}
              />
            ))}
          <RequiredLabel>*Campos obrigatórios</RequiredLabel>
          <PrimaryButtonStyled
            type="submit"
            disabled={!this.areFieldsValid()}
            version={2}
          >
            Continuar
          </PrimaryButtonStyled>
        </Container>
      </form>
    );
  }
}

AddressForm.propTypes = {
  address: addressShape.isRequired,
  sendAddressDocument: PropTypes.func.isRequired,
  goToAddressStage: PropTypes.func.isRequired,
  goToNextStage: PropTypes.func.isRequired,
  identificationId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  addressType: PropTypes.string.isRequired
};

const mapStateToProps = ({
  identification: { identificationId, token },
  address: { addressStage: addressType }
}) => ({ identificationId, token, addressType });

const mapDispatchToProps = {
  sendAddressDocument: sendAddress,
  goToAddressStage: setAddressStage,
  goToNextStage: incrementStage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressForm);
