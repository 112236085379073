import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PrimaryButton } from 'liber-components';
import PinForm from '../PinForm/PinForm';
import { resetPin, FINISHED_STAGE, EXPIRED_STAGE } from '../../ducks/pinResetState';
import ImageMessage from '../ImageMessage/ImageMessage';
import check from '../../assets/images/Sucesso.png';
import confused from '../../assets/images/Lebre_Confusa+.png';
import { changePage, PIN_RESET_REQUEST } from '../../ducks/pageState';

const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  return token;
};

const sendPinReset = (token, finishReset, hasExtension) => pin => {
  finishReset(token, pin, hasExtension);
};

const restartReset = redirect => () => {
  redirect(PIN_RESET_REQUEST);
};

export const PinReset = ({ finishReset, hasExtension, stage, redirect }) => {
  const token = getUrlParams();

  if (stage === FINISHED_STAGE) {
    return (
      <ImageMessage
        title="PIN redefinido com sucesso!"
        message={
          <>
            Agora você já pode acessar a plataforma da Liber Capital! <br />
            Qualquer dúvida, entre em contato com nosso suporte.
          </>
        }
        imgSrc={check}
        imgCropped={false}
      />
    );
  }

  if (stage === EXPIRED_STAGE) {
    return (
      <ImageMessage
        title="Link expirado"
        message={
          <>
            O link para redefinir seu PIN expirou.
            <br />
            Faça uma nova solicitação para a redefinição do PIN. <br />
            Qualquer dúvida, entre em contato com o nosso suporte.
          </>
        }
        imgSrc={confused}
      >
        <PrimaryButton onClick={restartReset(redirect)} size="large" version={2}>
          Solicitar redefinição novamente
        </PrimaryButton>
      </ImageMessage>
    );
  }
  return (
    <PinForm title="Redefinir PIN" onFinish={sendPinReset(token, finishReset, hasExtension)} />
  );
};

PinReset.propTypes = {
  finishReset: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  hasExtension: PropTypes.bool.isRequired,
  stage: PropTypes.string.isRequired,
};

const mapStateToProps = ({ pinReset: { stage } }) => ({ stage });

export default connect(
  mapStateToProps,
  { finishReset: resetPin, redirect: changePage },
)(PinReset);
