import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changePage } from '../../ducks/pageState';
import Loading from '../Loading/Loading';

export const Route = ({ permission, children, fallback, redirectTo, redirect }) => {
  if (permission) {
    return children;
  }
  if (redirectTo) {
    redirect(redirectTo);
    return <Loading loading />;
  }

  return fallback;
};

Route.propTypes = {
  page: PropTypes.string.isRequired,
  permission: PropTypes.bool,
  fallback: PropTypes.node,
  children: PropTypes.node.isRequired,
  redirectTo: PropTypes.string,
  redirect: PropTypes.func.isRequired,
};

Route.defaultProps = {
  permission: true,
  fallback: null,
  redirectTo: null,
};

const mapDispatchToProps = {
  redirect: changePage,
};

export default connect(
  null,
  mapDispatchToProps,
)(Route);
