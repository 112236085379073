import styled from 'styled-components';

import { MdClose, Button } from 'liber-components';

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 16px;
`;

export const DialogActions = styled.div`
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e8ebee;
  min-height: 53px;
`;

export const CloseIcon = styled(MdClose)`
  width: 16px;
  height: 16px;
  fill: #798e9b;
`;

export const ButtonStyled = styled(Button)`
  margin-right: 16px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const IconButton = styled(Button)`
  padding: 16px;
`;

export const BaseContainer = styled.div`
  min-height: 45vh;
  min-width: 546px;
  width: 100%;
  display: flex;

  flex-direction: column;
  box-sizing: border-box;
`;
