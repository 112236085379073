import React from 'react';

import { Wrapper, Img } from './Done.styles';
import success from '../../assets/images/Sucesso.png';
import HeaderMessages from '../HeaderMessages/HeaderMessages';

export const Done = () => (
  <Wrapper>
    <Img src={success} alt="Localização" />
    <HeaderMessages
      title="Identificação completa!"
      details={
        <>
          Vamos analisar suas informações para habilitar <br />
          seu computador. Isso não deve demorar mais do que 3 dias úteis!
        </>
      }
    />
  </Wrapper>
);

export default Done;
