import React from 'react';
import ImageMessage from '../../../ImageMessage/ImageMessage';
import filledInfo from '../../../../assets/images/data_sent.svg';

const DataSent = () => {
  return (
    <ImageMessage
      imgSrc={filledInfo}
      title="Informações já enviadas"
      message={
        <>
          Você já nos forneceu todos os dados necessários <br />
          para operar com a Liber Capital.
        </>
      }
    />
  );
};

export default DataSent;
