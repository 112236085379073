import styled, { css } from 'styled-components';
import { media } from 'liber-components';

const isRegularSize = size => size === 'regular';

export const Image = styled.img`
  ${({ size }) => {
    if (isRegularSize(size)) {
      return css`
        width: 174px;
        height: auto;
        margin-bottom: 56px;
        ${media.medium`
            width: 300px;
          `}
      `;
    } else if (size === 'medium') {
      return css`
        width: 174px;
        height: auto;
        margin-bottom: 56px;
        ${media.medium`
            width: 207px;
          `}
      `;
    }
    return css`
      height: auto;
      width: auto;
      margin-bottom: 24px;
    `;
  }}
`;

export const CroppedImage = styled(Image)`
  background-color: #f2f9fe;
  border-radius: 50%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 598px;
  ${({ size }) =>
    isRegularSize(size)
      ? css`
          height: 80vh;
        `
      : null}
  justify-content: center;
`;

export const ChildContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 64px;

  ${media.medium`
    margin-top: 56px;
  `}
`;

export const Title = styled.span`
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;

  letter-spacing: normal;
  text-align: center;
  color: #405f71;
  margin-bottom: 16px;

  ${({ size }) =>
    isRegularSize(size) || size === 'medium'
      ? css`
          line-height: 1.39;
          font-size: 20px;

          ${media.medium`
            font-size: 36px;
            `}
        `
      : css`
          font-size: 20px;
          line-height: 1.1;
        `}
`;

export const Message = styled.span`
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #798e9b;

  ${({ size }) =>
    isRegularSize(size) || size === 'medium'
      ? css`
          line-height: 1.4;
          font-size: 16px;

          ${media.medium`
            font-size: 20px;
            `}
        `
      : css`
          font-size: 16px;
          line-height: 1.31;
        `}
`;
