import React from 'react';

import { Wrapper, Img } from './DonePendecies.styles';
import success from '../../assets/images/Sucesso.png';
import HeaderMessages from '../HeaderMessages/HeaderMessages';

export const DonePendecies = () => (
  <Wrapper>
    <Img src={success} alt="Localização" />
    <HeaderMessages
      title="Informações enviadas"
      details={
        <>
          Os dados necessários para operar com a <br />
          Liber Capital foram enviados com sucesso!
        </>
      }
    />
  </Wrapper>
);

export default DonePendecies;
