const formatBase64Image = b64Image => {
  const [, pureBase64] = b64Image.split(',');
  return pureBase64;
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(formatBase64Image(reader.result));
    reader.onerror = error => reject(error);
  });
