import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField } from 'liber-components';
import FormTemplate from '../../FormTemplate/FormTemplate';
import { ContentForm, StyledPrimaryButton } from './UserStatusCheck.styles';
import { checkStatus } from '../../../ducks/pinResetState';

const formatCpf = cpf =>
  cpf
    .split('.')
    .join('')
    .split('-')
    .join('');

const submitCpf = (cpf, checkStatusAndRedirect) => event => {
  event.preventDefault();
  const formattedCpf = formatCpf(cpf);
  checkStatusAndRedirect(formattedCpf);
};

export const UserStatusCheck = ({ checkStatusAndRedirect }) => {
  const [cpf, setCpf] = useState('');
  return (
    <FormTemplate
      title="Insira seu CPF"
      subTitle="Para solicitar sua redefinição do PIN, precisamos validar seu CPF."
    >
      <ContentForm
        onSubmit={submitCpf(cpf, checkStatusAndRedirect)}
        id="check-status-form"
      >
        <TextField
          version={2}
          value={cpf}
          onChange={setCpf}
          width="346px"
          label="CPF"
          mask="999.999.999-99"
        />
        <StyledPrimaryButton type="submit" version={2} size="large">
          Enviar CPF
        </StyledPrimaryButton>
      </ContentForm>
    </FormTemplate>
  );
};

UserStatusCheck.propTypes = {
  checkStatusAndRedirect: PropTypes.func.isRequired,
};

export default connect(
  null,
  { checkStatusAndRedirect: checkStatus },
)(UserStatusCheck);
