import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField } from 'liber-components';
import { identificateCPF } from '../../ducks/identificationState';
import { FooterText, StyledPrimaryButton, Alert, Content, Link } from './UserIdentification.styles';
import FormTemplate from '../FormTemplate/FormTemplate';
import { checkCookie } from '../../utils/CookieHelper';
import { COOKIE_IN_PROGRESS } from '../../utils/constants';

export class UserIdentification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpf: props.cpfState,
      alert: checkCookie(COOKIE_IN_PROGRESS),
    };
  }

  formatCPF = () => {
    const { cpf } = this.state;
    const noDots = cpf.split('.').join('');
    const formattedCPF = noDots.split('-').join('');
    return formattedCPF;
  };

  setCpf = value => this.setState({ cpf: value });

  confirmCpf = event => {
    const { setIdentification } = this.props;
    const formattedCPF = this.formatCPF();
    event.preventDefault();
    setIdentification(formattedCPF);
  };

  closeAlert = () => {
    this.setState({ alert: false });
  };

  render() {
    const { cpf, alert } = this.state;

    return (
      <>
        {alert && (
          <Alert
            onClose={this.closeAlert}
            additionalInfoContent="Por favor, reinicie a identificação. Qualquer dúvida, entre em contato com nosso suporte!"
          >
            Identificação expirada
          </Alert>
        )}
        <FormTemplate
          title="Identificação"
          subTitle="Para começar, precisamos do seu CPF."
          details={
            <>
              Com ele conseguimos encontrar informações <br /> que vão deixar sua identificação mais
              rápida!
            </>
          }
          footerChildren={
            <FooterText>
              Ao continuar, você concorda com nossa{' '}
              <Link
                href=" https://assets.libercapital.com.br/contratos/politica-de-privacidade"
                target="__blank"
              >
                Politica de Privacidade{' '}
              </Link>{' '}
              e com as <br />{' '}
              <Link
                href="https://assets.libercapital.com.br/contratos/condicoes-uso-plataforma"
                target="__blank"
              >
                Condições de Uso da Pataforma
              </Link>
            </FooterText>
          }
        >
          <form id="identification-form" onSubmit={this.confirmCpf}>
            <Content>
              <TextField
                id="CPF-text-field"
                width="346px"
                version={2}
                label="CPF"
                openLabel
                value={cpf}
                onChange={this.setCpf}
                hintText="999.999.999-99"
                mask="999.999.999-99"
              />
              <StyledPrimaryButton id="CPF-confirm-button" type="submit" version="2" size="large">
                Enviar CPF
              </StyledPrimaryButton>
            </Content>
          </form>
        </FormTemplate>
      </>
    );
  }
}

UserIdentification.propTypes = {
  setIdentification: PropTypes.func.isRequired,
  cpfState: PropTypes.string.isRequired,
};

export default connect(
  ({ identification: { cpf } }) => ({ cpfState: cpf }),
  { setIdentification: identificateCPF },
)(UserIdentification);
