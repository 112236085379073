import React from 'react';

import { Wrapper, Img } from './DonePin.styles';
import success from '../../assets/images/Sucesso.png';
import HeaderMessages from '../HeaderMessages/HeaderMessages';

export const DonePin = () => (
  <Wrapper>
    <Img src={success} alt="Pronto" />
    <HeaderMessages
      title="PIN criado com sucesso!"
      details={
        <>
          Agora você já pode acessar a plataforma da Liber Capital! <br />
          Qualquer dúvida, entre em contato com nosso suporte.
        </>
      }
    />
  </Wrapper>
);

export default DonePin;
