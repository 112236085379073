import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  RESIDENTIAL,
  BUSINESS,
  BUSINESS_CONFIRMATION,
} from '../../ducks/addressState';
import UserAddress from '../UserAddress/UserAddress';
import BusinessAddressSelection from '../BusinessAddressSelection/BusinessAddressSelection';

const FORM_STAGES = [RESIDENTIAL, BUSINESS];

export const Address = props => {
  const { addressStage } = props;
  return FORM_STAGES.includes(addressStage) ? (
    <UserAddress />
  ) : (
    <BusinessAddressSelection />
  );
};

Address.propTypes = {
  addressStage: PropTypes.oneOf([RESIDENTIAL, BUSINESS, BUSINESS_CONFIRMATION])
    .isRequired,
};

const mapStateToProps = ({ address: { addressStage } }) => ({ addressStage });

export default connect(mapStateToProps)(Address);
