export const HOME = '/';
export const PIN_CREATION = 'pin';
export const PIN_DONE = 'done';
export const PIN_RESET_REQUEST = 'forgot-pin';
export const PIN_RESET = 'pin-reset';
export const PENDENCIES = 'pendencies';
export const PENDENCIES_DONE = 'pendencies-done';

export const CHANGE_PAGE = 'liber-id/CHANGE_PAGE';
export const CLEAR_URL = 'liber-id/CLEAR_URL';

const initialState = {
  page: HOME,
};

const getInitialState = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get('page');

  if (page) {
    return { page };
  }

  return initialState;
};

const setPageOnURL = payload => {
  const { page } = payload;
  const urlParams = new URLSearchParams(window.location.search);
  if (page !== HOME) {
    urlParams.set('page', page);
  } else {
    urlParams.delete('page');
  }

  window.location.replace(`/?${urlParams.toString()}`);
};

export default function reducer(state = getInitialState(), action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_PAGE:
      setPageOnURL(payload);
      return state;
    default:
      return state;
  }
}

export function changePage(page) {
  return { type: CHANGE_PAGE, payload: { page } };
}

export function clearURL(urlParams, ...paramsToClear) {
  return dispatch => {
    dispatch({ type: CLEAR_URL });

    paramsToClear.forEach(param => {
      urlParams.delete(param);
    });

    const finalUrl = urlParams.toString().length > 0 ? `/?${urlParams.toString()}` : '/';

    window.history.replaceState({}, 'Liber ID', finalUrl);
  };
}
