/* eslint-disable no-param-reassign */
import React from 'react';
import pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const getFile = dataURI => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const bufferView = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    bufferView[i] = byteString.charCodeAt(i);
  }
  return new Blob([arrayBuffer], { type: mimeString });
};

const printToCanvas = (img, context) => {
  const imageElement = new Image();
  imageElement.onload = () => {
    context.drawImage(imageElement, 0, 0);
  };
  imageElement.src = img;
};

const createCamCapture = (videoRef, canvasRef, fileSetter, imgSetter) => () => {
  const { current: video } = videoRef;
  const { current: canvas } = canvasRef;
  if (video && canvas) {
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, 560, 420);
    const imageDataURL = canvas.toDataURL('image/png');
    fileSetter(getFile(imageDataURL));
    imgSetter(imageDataURL);
  }
};

const createUploadImage = (fileSetter, imgSetter) => file => {
  const reader = new FileReader();
  reader.onload = ({ target: { result } }) => {
    fileSetter(file);
    imgSetter(result);
  };
  reader.readAsDataURL(file);
};

const createUploadPdf = (fileSetter, pdfSetter) => async file => {
  const src = URL.createObjectURL(file);
  const loadingTask = pdfjs.getDocument(src);
  const pdf = await loadingTask.promise;
  fileSetter(file);
  pdfSetter(pdf);
};

const useCamStream = (videoRef, img, file) => {
  React.useEffect(() => {
    const { current: video } = videoRef;
    if (video && img === null) {
      navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
        video.srcObject = stream;
        video.play();
      });
    }
  }, [videoRef, img, file]);
};

const useCanvasPrint = (canvas, triggers, uploadImg, pdf, camImg) => {
  React.useEffect(() => {
    if (canvas.current) {
      canvas.current.parentNode.style.overflowY = 'hidden';
      canvas.current.parentNode.style.overflowX = 'hidden';
      const context = canvas.current.getContext('2d');
      context.clearRect(0, 0, 560, 420);
      if (camImg) printToCanvas(camImg, context);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas, camImg, uploadImg, pdf, ...triggers]);
};

const useMonoState = () => {
  const [camImg, setCamImgRaw] = React.useState(null);
  const [uploadImg, setUploadImgRaw] = React.useState(null);
  const [pdf, setPdfFileRaw] = React.useState(null);

  const setCamImg = value => {
    setUploadImgRaw(null);
    setPdfFileRaw(null);
    setCamImgRaw(value);
  };
  const setUploadImg = value => {
    setCamImgRaw(null);
    setPdfFileRaw(null);
    setUploadImgRaw(value);
  };
  const setPdfFile = value => {
    setCamImgRaw(null);
    setUploadImgRaw(null);
    setPdfFileRaw(value);
  };
  return [[uploadImg, setUploadImg], [pdf, setPdfFile], [camImg, setCamImg]];
};

export const useCam = () => {
  const [file, setFile] = React.useState(null);
  const [[uploadImg, setUploadImg], [pdf, setPdfFile], [camImg, setCamImg]] = useMonoState();

  const video = React.useRef(null);
  const camCanvas = React.useRef(null);
  const previewCanvas = React.useRef(null);

  const clearFile = () => {
    setFile(null);
    if (camImg) setCamImg(null);
    if (uploadImg) setUploadImg(null);
    if (pdf) setPdfFile(null);
  };

  const setStreamImage = createCamCapture(video, camCanvas, setFile, setCamImg);
  const setUploadImage = createUploadImage(setFile, setUploadImg);
  const setUploadPdf = createUploadPdf(setFile, setPdfFile);

  useCamStream(video, camImg, file);
  useCanvasPrint(previewCanvas, [], uploadImg, pdf, camImg);

  return [
    video,
    camCanvas,
    previewCanvas,
    file,
    clearFile,
    setStreamImage,
    setUploadImage,
    setUploadPdf,
  ];
};

export const useUpload = () => {
  const [file, setFile] = React.useState(null);
  const [showPreview, setShowPreview] = React.useState(false);
  const [[uploadImg, setUploadImg], [uploadPdf, setPdf]] = useMonoState();

  const clearFile = () => {
    setFile(null);
    if (uploadImg) setUploadImg(null);
    if (uploadPdf) setPdf(null);
  };

  const setUploadImage = createUploadImage(setFile, setUploadImg);
  const setUploadPdf = createUploadImage(setFile, setPdf);

  return {
    showPreview,
    setShowPreview,
    file,
    clearFile,
    setUploadImage,
    setUploadPdf,
    uploadImg,
    uploadPdf,
  };
};
