import styled from 'styled-components';
import { MdHelpCircle, media } from 'liber-components';

export const Bar = styled.div`
  background-color: white;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  box-shadow: 0 2px 4px 0 rgba(64, 95, 113, 0.12);
  ${media.small`
    display: flex;
  `}
`;

export const MdHelpCircleStyled = styled(MdHelpCircle)`
  height: 24px;
  width: 24px;
  fill: #b6c2c9;
`;

export const Logo = styled.img`
  height: 44px;
`;
