import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line
import { connect } from 'react-redux';

export const RouterSwitch = ({ children, page }) => {
  const [correctChild = null] = React.Children.toArray(children).filter(
    child => child.props.page === page,
  );

  return correctChild;
};

RouterSwitch.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired,
};

const mapStateToProps = ({ pageState: { page } }) => ({ page });

export default connect(mapStateToProps)(RouterSwitch);
