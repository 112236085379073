import styled, { css } from 'styled-components';

export const FieldName = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #b6c2c9;
`;

export const FieldValue = styled.span`
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.41px;
  text-align: left;
  color: #b6c2c9;
  ${({ spacing }) =>
    spacing
      ? css`
          margin-bottom: 16px;
        `
      : null}
`;
