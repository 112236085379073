import styled from 'styled-components';
import { TextField, PrimaryButton } from 'liber-components';

export const TextFieldStyled = styled(TextField)`
  margin-bottom: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RequiredLabel = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: right;
  color: #798e9b;
  width: 100%;
`;

export const PrimaryButtonStyled = styled(PrimaryButton)`
  margin-top: 32px;
`;
