import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Text,
  SecondaryText,
  getStyledIcon
} from './ContactInfo.styles';

const ContactInfo = ({ icon, contact, label, densePadding }) => {
  const Icon = getStyledIcon(icon);
  return (
    <Container densePadding={densePadding}>
      <Icon />
      <Text>{`${label}:`}</Text>
      <SecondaryText>{contact}</SecondaryText>
    </Container>
  );
};

ContactInfo.propTypes = {
  icon: PropTypes.func.isRequired,
  contact: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  densePadding: PropTypes.bool.isRequired
};

ContactInfo.defaultProps = {
  densePadding: false
};

export default ContactInfo;
