import { MdWhatsApp, MdPhone, MdEmailOutline } from 'liber-components';

export const CONTACTS = [
  {
    label: 'WhatsApp',
    icon: MdWhatsApp,
    contact: '(16) 9 9637-0760',
  },
  {
    label: 'Telefone',
    icon: MdPhone,
    contact: '(11) 4858-1265',
  },
  {
    label: 'E-mail',
    icon: MdEmailOutline,
    contact: 'contato@libercapital.com.br',
  },
];

export const COOKIE_STAGE_ID = 'liber-id/sign-up';
export const INCREMENT_STAGE = 'liber-id/sign-up/INCREMENT_STAGE';
export const DECREMENT_STAGE = 'liber-id/sign-up/DECREMENT_STAGE';
export const SET_FLOW = 'liber-id/sign-up/SET_FLOW';
export const STEPPER_VIEW = 'liber-id/sign-up/CHANGE_STEPPER_VIEW';
export const CHANGE_DOCUMENT_TYPE = 'liber-id/sign-up/CHANGE_DOCUMENT_TYPE';

export const COOKIE_IDENTIFICATION_ID = 'liber-id';
export const COOKIE_IN_PROGRESS = 'liber-id/in-progress';

export const REVOCATION_COOKIE = 'revocation-cookie';

export const UPLOAD_FLOW = 'upload';
export const WEBCAM_FLOW = 'webcam';

export const CNH_DOCUMENT = 'cnh';
export const RG_RNE_DOCUMENT = 'rg';

// Pendencies

export const ACCOUNT_TYPE = 'account_type';
export const ACCOUNT_INFO = 'account_info';

export const INCOME_STEP = 'income_step';
export const PATRIMONY_STEP = 'patrimony_step';
export const ACCOUNT_STEP = 'account_step';
export const DATA_SENT = 'data_sent';
