import api from '../api/api';
import { handleError } from '../utils/ErrorHelper';
import { setLoading } from './loadingState';

export const UPLOAD_LOCATION = 'liber-id/sign-up/UPLOAD_LOCATION';
export const SEND_FINISH = 'liber-id/sign-up/SEND_FINISH';

export function uploadLocation(
  { position, identificationId, token },
  onUpload = () => {},
) {
  return dispatch => {
    dispatch({ type: UPLOAD_LOCATION });
    dispatch(setLoading(true));

    return api
      .sendLocation(position, identificationId, token)
      .then(() => {
        onUpload();
      })
      .catch(handleError)
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function sendFinish(
  { identificationId, token },
  onRegisterFinished = () => {},
) {
  return dispatch => {
    dispatch({ type: SEND_FINISH });
    dispatch(setLoading(true));

    return api
      .sendFinish(identificationId, token)
      .then(() => {
        onRegisterFinished();
      })
      .catch(handleError)
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}
