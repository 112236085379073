import React from 'react';
import {
  Header,
  Title,
  SecondaryText,
  ContentBox,
  Wrapper,
  ContactsContainer
} from './HelpTooltip.styles';
import ContactInfo from '../ContactInfo/ContactInfo';
import { CONTACTS } from '../../utils/constants';

const HelpTooltip = () => {
  return (
    <Wrapper>
      <Header>
        <Title>Falar com Suporte</Title>
      </Header>
      <ContentBox>
        <SecondaryText>
          Em caso de dúvidas, entre em contato com o nosso suporte
        </SecondaryText>
        <ContactsContainer>
          {CONTACTS.map(contact => (
            <ContactInfo densePadding key={contact.label} {...contact} />
          ))}
        </ContactsContainer>
      </ContentBox>
    </Wrapper>
  );
};

export default HelpTooltip;
