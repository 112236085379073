import axios from 'axios';
import { toBase64 } from '../utils/ImageHelper';

class Api {
  constructor() {
    this.liberId = axios.create({
      baseURL: process.env.REACT_APP_ID_API,
    });
    this.liberAuth = axios.create({
      baseURL: process.env.REACT_APP_AUTH_API,
    });
  }

  identificateCPF(cpf) {
    return this.liberId.post('/api/identifications', { cpf });
  }

  sendLocation(position, identificationId, token) {
    const data = {
      kind: 'geolocation',
      data: {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        accuracy: position.coords.accuracy,
      },
    };
    return this.sendDocument(identificationId, token, data).then(() =>
      this.sendFinish(identificationId, token),
    );
  }

  sendFinish(identificationId, token) {
    return this.liberId.put(
      `/api/identifications/${identificationId}/finish`,
      {},
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  sendDocument(identificationId, token, data) {
    return this.liberId.put(`/api/identifications/${identificationId}/documents`, data, {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  }

  sendContactInformation(identificationId, token, userContact) {
    const { name, phone, email, socialName } = userContact;
    const formattedInfo = {
      kind: 'contact',
      data: {
        name,
        legal_name: name,
        phone_number: phone,
        email,
      },
    };
    if (socialName) {
      formattedInfo.data.display_name = socialName;
    }

    return this.sendDocument(identificationId, token, formattedInfo);
  }

  async sendImage(file, identificationId, token, documentKind) {
    const b64File = await toBase64(file);
    const data = {
      kind: documentKind,
      attachments: [{ content_type: file.type, content: b64File }],
    };
    return this.sendDocument(identificationId, token, data);
  }

  getUserIdentificationStatus(cpf) {
    return this.liberId.get('/api/identification_status', { params: { cpf } }).then(response => {
      const {
        data: { status },
      } = response;
      return status;
    });
  }

  revokeAnotherDevice(pin, cpf) {
    return this.liberAuth.post('/api/revocations', {
      cpf,
      pin,
      supports_immediate_revocation: true,
      push_type: 'apn',
      push_token: '123456',
    });
  }

  resetPin(token, pin) {
    return this.liberAuth.post('/api/pin-reset', { token, pin });
  }

  getAddressByZipCode(zipCode) {
    return this.liberId.get('/api/address_by_zipcode', {
      params: { zipcode: zipCode },
    });
  }

  getBankList = () => {
    return this.liberId.get('/api/banks').then(({ data }) => data);
  };

  async sendPinResetSelfie(cpf, file) {
    const b64File = await toBase64(file);

    return this.liberAuth
      .post('/api/request-pin-resets', {
        cpf,
        attachment: { content_type: file.type, content: b64File },
      })
      .then(response => {
        const {
          data: { pin_reset_id: resetId },
        } = response;
        return resetId;
      });
  }

  getIdJWT(authJWT) {
    return this.liberAuth
      .get('/api/session', {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${authJWT}`,
        },
        params: { service: 'id' },
      })
      .then(({ data: { jwt } }) => jwt);
  }

  sendPendency(idJWT, data) {
    return this.liberId.post('/api/documents', data, {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${idJWT}`,
      },
    });
  }

  sendBankAccount(idJWT, bankAccount) {
    const requestData = {
      kind: 'bank_account',
      data: bankAccount,
    };
    return this.sendPendency(idJWT, requestData);
  }

  sendFinancialData(idJWT, financialData) {
    const { income, netWorth } = financialData;
    const requestData = {
      kind: 'financial_data',
      data: {
        income,
        net_worth: netWorth,
      },
    };

    return this.sendPendency(idJWT, requestData);
  }
}

export default new Api();
