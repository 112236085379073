import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { PrimaryButton } from 'liber-components';

import ImageMessage from '../ImageMessage/ImageMessage';
import confused from '../../assets/images/Lebre_Confusa+.png';
import check from '../../assets/images/Sucesso.png';
import alert from '../../assets/images/alert.svg';
import {
  PIN,
  INITIAL,
  IN_REVIEW,
  APPROVED,
  setStage,
  setRevocation,
} from '../../ducks/revogationState';
import UserPin from '../UserPin/UserPin';

const changeStage = (goToStage, stage) => () => {
  goToStage(stage);
};

const IMAGE_PROPS = {
  [INITIAL]: {
    imgSrc: confused,
    title: 'Dispositivo novo?',
    message: `O CPF que você tentou registrar já está cadastrado 
    em outro dispositivo. Para ativar sua conta neste dispositivo, 
    precisamos que você revogue o acesso do dispositivo anterior.`,
    getChildren: ({ goToStage }) => (
      <PrimaryButton onClick={changeStage(goToStage, PIN)} size="large">
        Revogar dispositivo anterior
      </PrimaryButton>
    ),
  },
  [IN_REVIEW]: {
    imgSrc: alert,
    title: 'Revogação pendente',
    message: `O PIN inserido não é válido. Nossa equipe irá analisar sua 
    solicitação e em breve notificaremos você pela extensão para 
    iniciar o processo de identificação no dispositivo atual.`,
    imgCropped: false,
    size: 'medium',
    getChildren: () => null,
  },
  [APPROVED]: {
    imgSrc: check,
    title: 'Revogação aprovada!',
    message: `O acesso do seu dispositivo antigo foi revogado
    e agora você pode ativar este dispositivo.`,
    getChildren: ({ finishRevocation }) => (
      <PrimaryButton onClick={() => finishRevocation()} size="large">
        Ativar este dispositivo
      </PrimaryButton>
    ),
  },
};

export const Revogation = ({ stage, goToStage, finishRevocation }) => {
  if (stage === PIN) {
    return <UserPin />;
  }
  const { getChildren, ...imgProps } = IMAGE_PROPS[stage];
  return (
    <ImageMessage {...imgProps}>
      {getChildren({ goToStage, finishRevocation })}
    </ImageMessage>
  );
};

Revogation.propTypes = {
  stage: PropTypes.string.isRequired,
  goToStage: PropTypes.func.isRequired,
  finishRevocation: PropTypes.func.isRequired,
};

const mapStateToProps = ({ revogation: { stage } }) => ({ stage });

const mapDispatchToProps = {
  goToStage: setStage,
  finishRevocation: setRevocation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Revogation);
