import styled from 'styled-components';

import {
  PrimaryButton,
  TextField,
  Button,
  MdHelpCircle,
} from 'liber-components';

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 56px;
  width: 256px;
`;

export const InputContainer = styled.div`
  margin-bottom: ${props => (props.margin ? '0px' : '36px')};
`;

export const StyledTextField = styled(TextField)`
  /* margin-bottom: 24px; */
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: center;
`;

const BaseText = styled.span`
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
`;

export const FieldInfo = styled(BaseText)`
  font-size: 14px;
  line-height: 1.43;
  color: #b6c2c9;
  margin-bottom: 24px;
  margin-top: 8px;
  width: 100%;
`;

export const RequiredFields = styled(BaseText)`
  font-size: 14px;
  line-height: 1.43;
  text-align: right;
  color: #798e9b;
  width: 100%;
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const TooltipTitle = styled(BaseText)`
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: #405f71;
  margin-bottom: 8px;
`;

export const TooltipText = styled(BaseText)`
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.01px;
  color: #798e9b;
`;

export const StyledButton = styled(Button)`
  color: #4cbaff;
  padding-left: 0;
`;

export const SocialNameContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledMdHelpCircle = styled(MdHelpCircle)`
  width: 14px;
  height: 14px;
  fill: #b6c2c9;
`;

export const IconContainer = styled.div`
  margin-left: 8px;
`;
