import { combineReducers } from 'redux';
import identification from './identificationState';
import revogation from './revogationState';
import stage from './stageState';
import address from './addressState';
import loading from './loadingState';
import pinReset from './pinResetState';
import pageState from './pageState';
import pendencies from './pendenciesState';

export default combineReducers({
  identification,
  revogation,
  stage,
  address,
  loading,
  pinReset,
  pageState,
  pendencies,
});
