import { toast } from 'liber-components';

export const handleError = error => {
  const { response } = error;
  if (response) {
    const {
      data: { messages = ['Houve um erro na requisição, entre em contato com o suporte'] },
    } = response;
    const [message] = messages;
    toast({ message: 'Erro', info: message }, 'error', 5000);
  } else {
    console.error(error);
  }
};
