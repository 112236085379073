import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Label, Separator } from 'liber-components';

import { Container, Step } from './StepperContainer.styles';
import { ACCOUNT_STEP, INCOME_STEP, PATRIMONY_STEP } from '../../utils/constants';

const stepReference = {
  [ACCOUNT_STEP]: 'Dados bancários',
  [INCOME_STEP]: 'Renda',
  [PATRIMONY_STEP]: 'Patrimônio',
};

const mapPendeciesToSteps = pendencies => {
  const steps = [];
  if (pendencies.includes('bank_account')) {
    steps.push(ACCOUNT_STEP);
  }
  if (pendencies.includes('financial_data')) {
    steps.push(INCOME_STEP);
    steps.push(PATRIMONY_STEP);
  }
  return steps;
};

export const PendenciesStepper = ({ pendencies, currentStep }) => {
  const steps = mapPendeciesToSteps(pendencies);

  return (
    <Container small>
      {steps.map((step, index) => {
        const isChecked = steps.indexOf(currentStep) >= index;
        const isFirst = index === 0;

        return (
          <>
            {isFirst ? null : <Separator full={isChecked} />}
            <Step key={step} type={isChecked ? 'checked' : 'final'}>
              {index + 1}
              <Label checked={isChecked}>{stepReference[step]}</Label>
            </Step>
          </>
        );
      })}
    </Container>
  );
};

PendenciesStepper.propTypes = {
  pendencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.string.isRequired,
};

const mapStateToProps = ({ pendencies: { pendencies, step } }) => ({
  pendencies,
  currentStep: step,
});

export default connect(mapStateToProps)(PendenciesStepper);
