import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton, Modal } from 'liber-components';
import {
  DialogContent,
  DialogActions,
  ButtonStyled,
  BaseContainer,
  IconContainer,
  CloseIcon,
  IconButton,
} from './ConfirmationDialog.styles';
import ImageMessage from '../ImageMessage/ImageMessage';
import papers from '../../assets/images/papers.png';

const ConfirmationDialog = ({ open, onClose, onClick }) => {
  return (
    <Modal show={open} elementId="root" onLeaved={onClose}>
      <BaseContainer>
        <IconContainer>
          <IconButton version={2} size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </IconContainer>
        <DialogContent>
          <ImageMessage
            imgSrc={papers}
            imgCropped={false}
            size="small"
            title="Você anotou o seu PIN?"
            message={
              <>
                Lembre-se de que você precisará usá-lo <br /> com frequência em
                nossa plataforma.
              </>
            }
          />
        </DialogContent>
        <DialogActions>
          <ButtonStyled version={2} onClick={onClose}>
            Cancelar
          </ButtonStyled>
          <PrimaryButton
            version={2}
            id="SETTING-PIN-confirm-button"
            onClick={onClick}
          >
            Continuar
          </PrimaryButton>
        </DialogActions>
      </BaseContainer>
    </Modal>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

ConfirmationDialog.defaultProps = {
  onClose: () => {},
  onClick: () => {},
};

export default ConfirmationDialog;
