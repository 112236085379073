import React from 'react';
import confused from '../../../assets/images/Lebre_Confusa+.png';
import ImageMessage from '../../ImageMessage/ImageMessage';
import MobileStoreButton from '../../MobileStoreButton/MobileStoreButton';
import { ButtonsContainer } from './UnregisteredUser.styles';

const UnregisteredUser = () => {
  return (
    <ImageMessage
      title="Identificação inexistente"
      message={
        <>
          Você não possui um cadastro com o CPF informado. <br />
          Baixe nosso app em seu smartphone e faça sua identificação para
          acessar nossa plataforma!
        </>
      }
      imgSrc={confused}
    >
      <ButtonsContainer>
        <MobileStoreButton device="pc" store="android" />
        <MobileStoreButton device="pc" store="ios" />
      </ButtonsContainer>
    </ImageMessage>
  );
};

export default UnregisteredUser;
