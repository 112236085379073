import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField } from 'liber-components';
import FormTemplate from '../FormTemplate/FormTemplate';
import {
  StyledPrimaryButton,
  StyledCardFooter,
  Container,
} from './UserPin.styles';
import { revokeDevice } from '../../ducks/revogationState';

const updatePin = setPin => value => setPin(value);

const revokeAnotherDevice = (revoke, cpf, pin) => () => {
  revoke(pin, cpf);
};

export const UserPin = props => {
  const { revokeOldDevice, cpf } = props;
  const [pin, setPin] = useState('');
  return (
    <FormTemplate
      title="Revogar dispositivo anterior"
      details={`Digite seu PIN no espaço abaixo para revogar o acesso do 
    dispositivo anterior e solicitar acesso no dispositivo atual.`}
      FooterComponent={StyledCardFooter}
    >
      <Container>
        <TextField
          version={2}
          label="PIN"
          type="password"
          showPasswordToggle
          width="346px"
          mask="9999"
          show
          value={pin}
          onChange={updatePin(setPin)}
          maskChar={null}
        />
        <StyledPrimaryButton
          disabled={pin.length < 4}
          onClick={revokeAnotherDevice(revokeOldDevice, cpf, pin)}
          version={2}
          size="large"
        >
          Confirmar
        </StyledPrimaryButton>
      </Container>
    </FormTemplate>
  );
};

UserPin.propTypes = {
  revokeOldDevice: PropTypes.func.isRequired,
  cpf: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  revokeOldDevice: revokeDevice,
};

const mapStateToProps = ({ identification: { cpf } }) => ({ cpf });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPin);
