import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import FormTemplate from '../../../FormTemplate/FormTemplate';
import {
  CardContent,
  SectionTitle,
  MandatoryFields,
  FormField,
  HolderInfoContainer,
  SelectField,
} from './AccountInfo.styles';
import { PrimaryButton } from '../Pendencies.styles';
import withDataFetched from '../../../../utils/withDataFetched';
import HolderInfo from '../HolderInfo/HolderInfo';
import { registerBankAccount } from '../../../../ducks/pendenciesState';
import api from '../../../../api/api';

const setValue = setState => value => {
  setState(value);
};

const areFieldsFilled = fields =>
  Object.keys(fields).reduce(
    (previousValue, currentFieldName) =>
      previousValue &&
      fields[currentFieldName].length > 0 &&
      !fields[currentFieldName].includes('_'),
    true,
  );

const formatBankData = (fields, user, isCurrentUserHolder) => {
  const { agency, agencyDigit, account, digit, bank, name, cpf } = fields;
  const { name: userName, cpf: userCPF } = user;

  const holderInfo = isCurrentUserHolder
    ? { holder: { name: userName, cpf: userCPF } }
    : { holder: { name, cpf } };

  const coHolderInfo = isCurrentUserHolder ? {} : { co_holder: { name: userName, cpf: userCPF } };

  return {
    branch: agency,
    branch_cd: agencyDigit,
    account,
    account_cd: digit,
    bank_code: bank,
    ...holderInfo,
    ...coHolderInfo,
  };
};

export const AccountInfo = ({ onConfirm, accountKind, banks, idJWT, user }) => {
  const [agency, setAgency] = useState('');
  const [agencyDigit, setAgencyDigit] = useState('');
  const [account, setAccount] = useState('');
  const [digit, setDigit] = useState('');
  const [cpf, setCpf] = useState('');
  const [name, setName] = useState('');
  const [bank, setBank] = useState('');

  const isCurrentUserHolder = ['individual', 'joint-holder'].includes(accountKind);

  const baseFields = { agency, agencyDigit, account, digit, bank };

  const fields = isCurrentUserHolder ? baseFields : { ...baseFields, cpf, name };

  return (
    <FormTemplate title="Dados bancários" subTitle="Agora, precisamos dos seus dados bancários.">
      <form
        id="bank-form"
        onSubmit={event => {
          event.preventDefault();
          const bankAccount = formatBankData(fields, user, isCurrentUserHolder);
          onConfirm(idJWT, bankAccount);
        }}
      >
        <CardContent>
          <SectionTitle>Dados do titular</SectionTitle>
          <HolderInfoContainer marginTop>
            {isCurrentUserHolder ? (
              <HolderInfo />
            ) : (
              <>
                <FormField value={name} onChange={setValue(setName)} label="Nome completo*" />
                <FormField
                  value={cpf}
                  onChange={setValue(setCpf)}
                  label="CPF*"
                  mask="999.999.999-99"
                />
              </>
            )}
          </HolderInfoContainer>
          <SectionTitle>Dados da conta</SectionTitle>
          <SelectField label="Banco*" onChange={setValue(setBank)}>
            {banks.map(({ code, bank: bankName }) => (
              <option key={bankName} value={code}>
                {`${code} - ${bankName}`}
              </option>
            ))}
          </SelectField>
          <FormField
            value={agency}
            maskChar={null}
            onChange={setValue(setAgency)}
            label="Agência*"
            mask="9999"
          />
          <FormField
            value={agencyDigit}
            maskChar={null}
            onChange={setValue(setAgencyDigit)}
            label="Digito"
            mask="99"
          />
          <FormField
            value={account}
            onChange={setValue(setAccount)}
            maskChar={null}
            label="Número da conta*"
            mask="99999"
          />
          <FormField
            value={digit}
            onChange={setValue(setDigit)}
            label="Dígito*"
            mask="9"
            maskChar={null}
          />
          <MandatoryFields>*Campos obrigatórios</MandatoryFields>
          <PrimaryButton disabled={!areFieldsFilled(fields)} type="submit">
            Continuar
          </PrimaryButton>
        </CardContent>
      </form>
    </FormTemplate>
  );
};

AccountInfo.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  idJWT: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
  }).isRequired,
  banks: PropTypes.arrayOf(
    PropTypes.shape({
      bank: PropTypes.string,
      code: PropTypes.string,
    }),
  ).isRequired,
  accountKind: PropTypes.oneOf(['individual', 'joint-holder', 'joint-not-holder']).isRequired,
};

const mapStateToProps = ({ pendencies: { accountKind, user, idJWT } }) => ({
  accountKind,
  user,
  idJWT,
});

const mapDispatchToProps = {
  onConfirm: registerBankAccount,
};

export default compose(
  withDataFetched(api.getBankList),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AccountInfo);
