/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'liber-components';

export const Wrapper = styled.div`
  width: 852px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VideoContainer = styled.div`
  position: relative;
`;

export const PreviewContainer = styled.div`
  width: 560px;
  height: 420px;
  margin-top: 36px;
  box-shadow: 15px 15px 0px 1px #cfedfe;
  overflow-x: hidden;
`;
export const Video = styled.video`
  width: 560px;
  height: 420px;
  margin-top: 36px;
  transform: rotateY(180deg);
`;

export const HiddenInput = styled.input.attrs(() => ({ type: 'file' }))`
  display: none;
`;

export const ConfirmCanvas = styled.canvas.attrs(() => ({
  width: '560',
  height: '420',
}))``;

export const HiddenCanvas = styled(ConfirmCanvas)`
  margin-top: 0px;
  display: none;
  box-shadow: none;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
`;

export const Button = styled(PrimaryButton)`
  width: 256px;
  margin: 0 12px;
  font-size: 16px;
`;

export const BackButton = styled(SecondaryButton)`
  width: 256px;
  border-width: 1px;
  height: 36px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 12px;
`;

export const Footer = styled.div`
  margin-top: 32px;
`;

export const CnhSvg = props => {
  const style = {
    fill: 'none',
    stroke: '#fff',
    strokeMiterlimit: 10,
    strokeWidth: '4px',
  };
  return (
    <svg {...props} width="598.197" height="417.094" viewBox="0 0 598.197 417.094">
      <g transform="translate(2 2)">
        <g transform="translate(103.274 124.987)">
          <rect
            style={style}
            width="261.177"
            height="29.934"
            rx="4"
            transform="translate(196.07 0)"
          />
          <rect
            style={style}
            width="261.926"
            height="131.711"
            rx="4"
            transform="translate(196.07 86.083)"
          />
          <rect
            style={style}
            width="103.274"
            height="29.934"
            rx="4"
            transform="translate(354.722 44.175)"
          />
          <rect
            style={style}
            width="149.672"
            height="29.934"
            rx="4"
            transform="translate(196.07 44.175)"
          />
          <rect
            style={style}
            width="182.6"
            height="214.031"
            rx="4"
            transform="translate(0 6.778)"
          />
          <rect
            style={style}
            width="182.6"
            height="214.031"
            rx="4"
            transform="translate(0 6.778)"
          />
          <path
            style={style}
            d="M715.508,1082.937c-3.423-13.758-22.969-20.463-29.72-22.626-7.419-2.38-17.976-2.943-24.777-4.327-3.9-.778-9.558-2.726-11.46-4.8s-.76-21.328-.76-21.328a62.6,62.6,0,0,0,5.421-9.389c1.9-4.369,3.995-16.4,3.995-16.4s3.9,0,5.277-6.229c1.474-6.792,3.8-9.432,3.52-14.494-.286-4.975-3.282-4.845-3.282-4.845s2.9-7.225,3.234-22.193c.428-17.781-14.885-35.3-42.609-35.3-28.105,0-43.085,17.522-42.656,35.3.38,14.969,3.186,22.193,3.186,22.193s-3-.13-3.282,4.845c-.284,5.062,2.046,7.7,3.52,14.494,1.331,6.229,5.277,6.229,5.277,6.229s2.092,12.028,3.995,16.4a62.593,62.593,0,0,0,5.421,9.389s1.142,19.251-.76,21.328-7.561,4.023-11.46,4.8c-6.8,1.384-17.357,1.947-24.777,4.327-6.752,2.163-26.3,8.868-29.72,22.626a3.561,3.561,0,0,0,3.757,4.2H711.8A3.55,3.55,0,0,0,715.508,1082.937Z"
            transform="translate(-533 -866.325)"
          />
        </g>
        <rect style={style} width="594.197" height="413.094" rx="8" transform="translate(0)" />
      </g>
    </svg>
  );
};

export const Mask = styled(CnhSvg)`
  position: absolute;
  width: 445px;
  height: 309px;
  top: calc(50% - 136.5px);
  left: calc(50% - 222.5px);
  transform: rotateY(180deg);
`;
