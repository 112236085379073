import React, { useState } from 'react';
import Loading from '../components/Loading/Loading';

const withDataFetched = fetchMethod => Component => {
  return props => {
    const [data, setData] = useState(null);

    React.useEffect(() => {
      fetchMethod().then(requestData => {
        setData(requestData);
      });
    }, []);

    if (data === null) {
      return <Loading loading />;
    }

    return <Component {...props} {...data} />;
  };
};

export default withDataFetched;
