import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormTemplate from '../../../FormTemplate/FormTemplate';
import RadioButton from '../../../RadioButton/RadioButton';
import individual from '../../../../assets/images/individual_aacount.svg';
import jointHolder from '../../../../assets/images/joint_account_holder.svg';
import jointNotHolder from '../../../../assets/images/joint_account_not_holder.svg';
import { RadioButtonsContainer, PrimaryButton } from './AccountType.styles';
import { ACCOUNT_INFO } from '../../../../utils/constants';
import { setAccountKind, changeView } from '../../../../ducks/pendenciesState';
import { BlueBold } from '../Pendencies.styles';

const options = [
  {
    id: 'individual',
    key: 'individual',
    imgSrc: individual,
    children: 'Conta individual',
  },
  {
    id: 'joint-holder',
    key: 'joint-holder',
    imgSrc: jointHolder,
    children: (
      <>
        Conta conjunta <br />
        (sou titular)
      </>
    ),
  },
  {
    id: 'joint-not-holder',
    key: 'joint-not-holder',
    imgSrc: jointNotHolder,
    children: (
      <>
        Conta conjunta <br />
        (não sou titular)
      </>
    ),
  },
];

export const AccountType = ({ confirmAccountType, goToStep }) => {
  const [firstOption] = options;

  const [selected, setSelected] = useState(firstOption.id);

  return (
    <FormTemplate
      title="Dados bancários"
      subTitle={
        <>
          Qual tipo de <BlueBold>conta bancária</BlueBold> você utilizará <br />
          para receber transferências da Liber?
        </>
      }
      renderCard={false}
    >
      <RadioButtonsContainer>
        {options.map(optionProps => (
          <RadioButton
            {...optionProps}
            checked={selected === optionProps.id}
            onChange={() => {
              setSelected(optionProps.id);
            }}
          />
        ))}
        <PrimaryButton
          onClick={() => {
            confirmAccountType(selected);
            goToStep(ACCOUNT_INFO);
          }}
        >
          Continuar
        </PrimaryButton>
      </RadioButtonsContainer>
    </FormTemplate>
  );
};

AccountType.propTypes = {
  confirmAccountType: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  confirmAccountType: setAccountKind,
  goToStep: changeView,
};

export default connect(
  null,
  mapDispatchToProps,
)(AccountType);
