import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, StyledCard, CardFooter } from './FormTemplate.styles';
import HeaderMessages from '../HeaderMessages/HeaderMessages';

const FormTemplate = ({
  title,
  subTitle,
  details,
  children,
  footerChildren,
  FooterComponent,
  renderCard
}) => {
  return (
    <Wrapper>
      <HeaderMessages title={title} subTitle={subTitle} details={details} />
      {renderCard ? (
        <StyledCard version={2}>
          {children}
          {footerChildren && (
            <FooterComponent>{footerChildren}</FooterComponent>
          )}
        </StyledCard>
      ) : (
        children
      )}
    </Wrapper>
  );
};

FormTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.node,
  details: PropTypes.node,
  children: PropTypes.node,
  footerChildren: PropTypes.node,
  FooterComponent: PropTypes.elementType,
  renderCard: PropTypes.bool
};

FormTemplate.defaultProps = {
  subTitle: null,
  children: null,
  footerChildren: null,
  FooterComponent: CardFooter,
  renderCard: true,
  details: null
};

export default FormTemplate;
