/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';

import { useCam } from '../../utils/useCam';
import HeaderMessages from '../HeaderMessages/HeaderMessages';
import { FooterMessage, FooterLink, FooterSubMessage } from '../Base.styles';
import {
  Wrapper,
  VideoContainer,
  Video,
  Button,
  HiddenCanvas,
  ConfirmCanvas,
  BackButton,
  ButtonContainer,
  Footer,
  HiddenInput,
  PreviewContainer,
} from './Cam.styles';

const validateFile = (file, accept) => {
  return accept.includes(file.type);
};

const handleDone = (onDone, file) => () => {
  onDone(file);
};

export const Cam = ({
  title,
  subTitle,
  previewTitle,
  previewSubTitle,
  uploadMessage,
  accept,
  acceptMessage,
  onDone,
}) => {
  const [
    video,
    camCanvas,
    previewCanvas,
    file,
    clearFile,
    setStreamImage,
    setUploadImage,
    setUploadPdf,
  ] = useCam();
  const hiddenInput = React.useRef(null);

  const uploadFile = ({ target: { files } }) => {
    const [src] = files;
    if (validateFile(src, accept)) {
      if (src.type === 'application/pdf') {
        setUploadPdf(src);
      } else {
        setUploadImage(src);
      }
    }
  };

  const triggerUpload = event => {
    event.preventDefault();
    if (hiddenInput.current) {
      hiddenInput.current.click();
    }
  };
  if (file) {
    return (
      <Wrapper>
        <HeaderMessages subTitle={previewTitle} details={previewSubTitle} />
        <PreviewContainer>
          <ConfirmCanvas ref={previewCanvas} />
        </PreviewContainer>
        <ButtonContainer>
          <BackButton onClick={clearFile}>Tirar Outra</BackButton>
          <Button version={2} onClick={handleDone(onDone, file)} disable={!file}>
            Continuar
          </Button>
        </ButtonContainer>
        <Footer>
          <FooterMessage>
            {uploadMessage}, <FooterLink onClick={triggerUpload}>clique aqui</FooterLink>.
            <HiddenInput accept={accept.join(', ')} ref={hiddenInput} onChange={uploadFile} />
          </FooterMessage>
          <FooterSubMessage>{acceptMessage}</FooterSubMessage>
        </Footer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <HeaderMessages title={title} subTitle={subTitle} />
      <VideoContainer>
        <Video ref={video} autoPlay />
        <HiddenCanvas ref={camCanvas} />
      </VideoContainer>
      <ButtonContainer>
        <Button version={2} onClick={setStreamImage}>
          Tira Foto
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

Cam.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  previewTitle: PropTypes.string.isRequired,
  previewSubTitle: PropTypes.string.isRequired,
  uploadMessage: PropTypes.string.isRequired,
  accept: PropTypes.arrayOf(PropTypes.string),
  acceptMessage: PropTypes.string,
  onDone: PropTypes.func,
};

Cam.defaultProps = {
  accept: ['image/png', 'image/jpeg', 'application/pdf'],
  acceptMessage: '*Formatos permitidos: PNG, JPG ou PDF',
  onDone: () => {},
};

export default Cam;
