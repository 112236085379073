import styled from 'styled-components';
import { PrimaryButton as RawPrimary, TextField as RawTextField } from 'liber-components';
import { formatMoney } from '../../../utils/MoneyHelper';

export const BlueBold = styled.span`
  color: #009dff;
`;

export const PrimaryButton = styled(RawPrimary).attrs(() => ({ version: 2, size: 'large' }))`
  margin-top: 40px;
  width: 256px;
`;

export const TextField = styled(RawTextField).attrs(({ onChange }) => ({
  width: '346px',
  version: 2,
  prefix: 'R$',
  openLabel: true,
  onChange: value => {
    onChange(formatMoney(value));
  },
}))``;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 48px 32px 32px 32px;
`;
