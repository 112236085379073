import { PrimaryButton } from 'liber-components';
import { WarningAlert } from 'liber-components/components/Alert';
import styled from 'styled-components';

export const FooterText = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: 0.01px;
  text-align: left;
  color: #798e9b;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 10px;
  margin-left: 8px;
  width: 142px;
`;

export const Content = styled.div`
  display: flex;
  padding: 48px 32px;
`;

export const Alert = styled(WarningAlert)`
  margin-bottom: 32px;
  width: 790px;
`;

export const Link = styled.a`
  text-decoration: underline;
  font-weight: bold;
  color: inherit;
`;
