import styled from 'styled-components';
import { TextField, PrimaryButton } from 'liber-components';

export const TextFieldStyled = styled(TextField)`
  margin-bottom: 24px;
`;

export const PrimaryButtonStyled = styled(PrimaryButton)`
  margin-top: 48px;
  width: 256px;
`;

export const ForgetLabel = styled.span`
  color: #009dff;
  text-decoration: underline;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  padding-top: 48px;
`;
