import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'liber-components';
import FormTemplate from '../FormTemplate/FormTemplate';
import {
  TextFieldStyled,
  PrimaryButtonStyled,
  ForgetLabel,
  Container,
} from './PinForm.styles';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

const changeText = setState => value => {
  setState(value);
};

const closeDialog = setDialogOpen => () => {
  setDialogOpen(false);
};

const isPinValid = (pin, pinConfirmation) =>
  pin.length === 4 && pinConfirmation.length === 4;

const areEqual = (pin, pinConfirmation) =>
  pin === pinConfirmation || pinConfirmation === '';

const onConfirm = setDialogOpen => event => {
  event.preventDefault();
  setDialogOpen(true);
};

const handleFinish = (setDialogOpen, onFinish, pin) => () => {
  setDialogOpen(false);
  onFinish(pin);
};

const PinForm = ({ onFinish, title }) => {
  const [pin, setPin] = useState('');
  const [pinConfirmation, setPinConfirmation] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <FormTemplate
      title={title}
      subTitle={
        <>
          Escolha seu PIN de 4 dígitos com carinho e{' '}
          <ForgetLabel>anote-o para não esquecer</ForgetLabel>!
        </>
      }
      details="Seu PIN será solicitado para acessar a plataforma e assinar documentos."
    >
      <form id="create-pin-form" onSubmit={onConfirm(setDialogOpen)}>
        <Container>
          <TextFieldStyled
            version={2}
            id="SETTING-PIN-input"
            type="password"
            width="346px"
            showPasswordToggle
            onChange={changeText(setPin)}
            value={pin}
            label={title}
            mask="9999"
            maskChar={null}
          />
          <TextField
            version={2}
            type="password"
            id="SETTING-PIN-confirmation-input"
            width="346px"
            showPasswordToggle
            onChange={changeText(setPinConfirmation)}
            value={pinConfirmation}
            label="Confirmar PIN"
            mask="9999"
            errorMessage={
              !areEqual(pin, pinConfirmation)
                ? 'Os PINs digitados não esão iguais'
                : null
            }
            maskChar={null}
          />
          <PrimaryButtonStyled
            disabled={
              !isPinValid(pin, pinConfirmation) ||
              !areEqual(pin, pinConfirmation)
            }
            type="submit"
            version={2}
            size="large"
          >
            Concluir
          </PrimaryButtonStyled>
        </Container>
      </form>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={closeDialog(setDialogOpen)}
        onClick={handleFinish(setDialogOpen, onFinish, pin)}
      />
    </FormTemplate>
  );
};

PinForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default PinForm;
