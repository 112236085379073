import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormTemplate from '../FormTemplate/FormTemplate';
import {
  Strong,
  RadioContainer,
  PrimaryButtonStyled,
} from './BusinessAddressSelection.styles';
import RadioButton from '../RadioButton/RadioButton';
import { incrementStage } from '../../ducks/stageState';
import {
  setAddressStage,
  sendAddress,
  addressShape,
  BUSINESS,
} from '../../ducks/addressState';

const OPTIONS = [
  {
    label: 'Sim',
    value: 'yes',
  },
  {
    label: 'Não',
    value: 'no',
  },
  {
    label: 'É o mesmo que o residencial',
    value: 'residential',
  },
];

const handleChange = (setSelected, option) => () => {
  setSelected(option);
};

const confirmSelection = (selected, props) => () => {
  const {
    goToNextStep,
    goToStage,
    submitAddress,
    residentialAddress,
    identificationId,
    token,
  } = props;

  if (selected === 'yes') {
    goToStage(BUSINESS);
  } else if (selected === 'no') {
    submitAddress(
      {
        identificationId,
        token,
        address: null,
        addressType: 'business',
      },
      goToNextStep,
    );
  } else if (selected === 'residential') {
    submitAddress(
      {
        identificationId,
        token,
        address: residentialAddress,
        addressType: 'business',
      },
      goToNextStep,
    );
  }
};

export const BusinessAddressSelection = props => {
  const [selected, setSelected] = useState('');
  const valuesReference = OPTIONS.map(({ value }) => value);
  return (
    <FormTemplate
      title="Endereço"
      subTitle={
        <>
          Você possui um endereço <Strong>comercial</Strong>?
        </>
      }
      renderCard={false}
    >
      <RadioContainer>
        {OPTIONS.map(({ value, label }) => (
          <RadioButton
            key={value}
            id={value}
            checked={selected === value}
            onChange={handleChange(setSelected, value)}
          >
            {label}
          </RadioButton>
        ))}
        <PrimaryButtonStyled
          onClick={confirmSelection(selected, props)}
          disabled={!valuesReference.includes(selected)}
        >
          Continuar
        </PrimaryButtonStyled>
      </RadioContainer>
    </FormTemplate>
  );
};

BusinessAddressSelection.propTypes = {
  goToNextStep: PropTypes.func.isRequired,
  goToStage: PropTypes.func.isRequired,
  submitAddress: PropTypes.func.isRequired,
  residentialAddress: addressShape.isRequired,
  identificationId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = ({
  address: {
    residential: { address },
  },
  identification: { identificationId, token },
}) => ({ residentialAddress: address, identificationId, token });

const mapDispatchToProps = {
  goToNextStep: incrementStage,
  goToStage: setAddressStage,
  submitAddress: sendAddress,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BusinessAddressSelection);
