import React from 'react';
import PropTypes from 'prop-types';
import { AppleImg, GoogleImg } from './MobileStoreButton.styles';
import appStore from '../../assets/images/App-store.svg';
import playStore from '../../assets/images/Google-play.svg';

const MobileStoreButton = ({ store, device }) => {
  if (store === 'ios') {
    return (
      <a href="https://apps.apple.com/br/app/liber-capital/id1451104122?mt=8">
        <AppleImg device={device} src={appStore} alt="Disponível na App Store" />
      </a>
    );
  }
  if (store === 'android') {
    return (
      // eslint-disable-next-line max-len
      <a href="https://play.google.com/store/apps/details?id=br.com.libercapital.libermobile.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <GoogleImg device={device} alt="Disponível no Google Play" src={playStore} />
      </a>
    );
  }
};

MobileStoreButton.propTypes = {
  store: PropTypes.oneOf(['ios', 'android']).isRequired,
  device: PropTypes.oneOf(['mobile', 'pc']),
};

MobileStoreButton.defaultProps = {
  device: 'mobile',
};

export default MobileStoreButton;
