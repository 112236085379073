import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Label, Separator, MdCheck } from 'liber-components';
import { Container, Step } from './StepperContainer.styles';

export const StepperContainer = ({ stage }) => {
  return (
    <Container>
      <Step type="checked">
        1<Label checked>Documento</Label>
      </Step>
      <Separator full={stage >= 1} />
      <Step type={stage >= 1 ? 'checked' : 'final'}>
        2<Label checked={stage >= 1}>Fotografia</Label>
      </Step>
      <Separator full={stage >= 2} />
      <Step type={stage >= 2 ? 'checked' : 'final'}>
        3<Label checked={stage >= 2}>Contato</Label>
      </Step>
      <Separator full={stage >= 3} />
      <Step type={stage >= 3 ? 'checked' : 'final'}>
        4<Label checked={stage >= 3}>Endereço</Label>
      </Step>
      <Separator full={stage >= 4} />
      <Step type={stage >= 4 ? 'checked' : 'final'}>
        5<Label checked={stage >= 4}>Localização</Label>
      </Step>
      <Separator full={stage >= 5} />
      <Step type={stage >= 5 ? 'checked' : 'final'}>
        <MdCheck />
        <Label checked={stage >= 5}>Conclusão</Label>
      </Step>
    </Container>
  );
};

StepperContainer.propTypes = {
  stage: PropTypes.number,
};

StepperContainer.defaultProps = {
  stage: 0,
};

const mapStateToProps = ({ stage: { stage } }) => ({ stage });

export default connect(mapStateToProps)(StepperContainer);
