import React from 'react';
import PropTypes from 'prop-types';
import {
  SliderContainer,
  Slider,
  ExampleContainer,
  Button,
  DotContainer,
  Dot,
  SliderContent,
  Image as Img,
  SliderText,
} from './ExampleCarousel.styles';
import wrongExample1 from '../../assets/images/photo_wrong_example1.JPG';
import wrongExample2 from '../../assets/images/photo_wrong_example2.JPG';
import correctExample from '../../assets/images/photo_correct_example.JPG';

const handleChangeSlider = (setter, value) => () => {
  setter(value);
};

const ExampleCarousel = ({ onDone }) => {
  const [active, setActive] = React.useState(0);
  return (
    <ExampleContainer>
      <SliderContainer>
        <Slider active={active}>
          <SliderContent>
            <Img src={wrongExample1} width={272} height={272} />
            <Img src={wrongExample2} width={272} height={272} />
            <SliderText>
              Não aceitaremos fotos com o documento ou outros
              <br />
              acessórios tampando seu rosto.
            </SliderText>
          </SliderContent>
          <SliderContent>
            <Img src={correctExample} width={272} height={272} type="right" />
            <SliderText>
              Seu rosto e o documento devem <br />
              aparecer nitidamente na fotografia.
            </SliderText>
          </SliderContent>
        </Slider>
      </SliderContainer>
      <DotContainer>
        <Dot active={active === 0} onClick={handleChangeSlider(setActive, 0)} />
        <Dot active={active === 1} onClick={handleChangeSlider(setActive, 1)} />
      </DotContainer>
      {active === 0 ? (
        <Button onClick={handleChangeSlider(setActive, 1)}>Próximo</Button>
      ) : (
        <Button onClick={onDone}>Tirar Foto</Button>
      )}
    </ExampleContainer>
  );
};

ExampleCarousel.propTypes = {
  onDone: PropTypes.func,
};
ExampleCarousel.defaultProps = {
  onDone: () => {},
};

export default ExampleCarousel;
