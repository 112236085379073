import styled from 'styled-components';
import { PrimaryButton as RawPrimary } from 'liber-components';

export const RadioButtonsContainer = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
`;

export const PrimaryButton = styled(RawPrimary).attrs(() => ({ version: 2, size: 'large' }))`
  margin-top: 64px;
  width: 256px;
`;
