import styled, { css } from 'styled-components';
import { LoadingBunny } from 'liber-components';

export const LoadingImage = styled(LoadingBunny)`
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  ${({ loading }) =>
    !loading
      ? css`
          display: none;
        `
      : null}
`;

export const LoadingBlock = styled.div`
  flex-grow: 1;
  display: flex;
  opacity: ${({ loading }) => (loading ? '0.3' : '1')};
  pointer-events: ${({ loading }) => (loading ? 'none' : null)};
  transition: opacity 150ms ease-in-out;
  display: inherit;
  flex-direction: inherit;
  position: inherit;
  min-height: ${({ loading }) => (loading ? '250px' : null)};
`;
