import styled, { css } from 'styled-components';

const handlePadding = ({ densePadding }) => {
  if (densePadding) {
    return css`
      padding: 8px 0px;
      padding-left: 24px;
    `;
  }
  return css``;
};

export const SecondaryText = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.01px;
  text-align: left;
  color: #798e9b;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  ${props => handlePadding(props)}
`;

export const Text = styled.span`
  margin-right: 4px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: -0.32px;
  text-align: left;
  color: #405f71;
`;

export const getStyledIcon = icon => {
  return styled(icon)`
    height: 24px;
    width: 24px;
    margin-right: 8px;
    fill: #4cbaff;
  `;
};
