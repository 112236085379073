import styled from 'styled-components';
import { ProgressContainer, Step as StepRaw } from 'liber-components';

export const Container = styled(ProgressContainer)`
  width: ${({ small = false }) => (small ? '20%' : '50%')};
  margin-bottom: 27px;
`;

export const Step = styled(StepRaw)`
  cursor: default;
`;
