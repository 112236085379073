import api from '../api/api';
import { handleError } from '../utils/ErrorHelper';
import extension from '../extension';
import { setLoading } from './loadingState';

/* Stages */
export const STATUS_STAGE = 'check-status';
export const SELFIE_STAGE = 'send-selfie';
export const INACTIVE_STAGE = 'inactive';
export const REQUESTED_STAGE = 'requested';
export const FINISHED_STAGE = 'finished';
export const EXPIRED_STAGE = 'expired';

/* Actions */
export const CHANGE_STAGE = 'liber-id/pin-reset/CHANGE_STAGE';
export const CHECK_STATUS = 'liber-id/pin-reset/CHECK_STATUS';
export const SET_CPF = 'liber-id/pin-reset/SET_CPF';
export const UPLOAD_SELFIE = 'liber-id/pin-reset/UPLOAD_SELFIE';
export const RESET_PIN = 'liber-id/pin-reset/RESET_PIN';

const initialState = {
  stage: STATUS_STAGE,
  cpf: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_STAGE:
    case SET_CPF:
      return { ...state, ...payload };

    default:
      return state;
  }
}

export function changeStage(stage) {
  return { type: CHANGE_STAGE, payload: { stage } };
}
export function saveCpf(cpf) {
  return { type: SET_CPF, payload: { cpf } };
}

export function checkStatus(cpf) {
  return dispatch => {
    dispatch({ type: CHECK_STATUS });
    dispatch(setLoading(true));

    return api
      .getUserIdentificationStatus(cpf)
      .then(status => {
        if (status === 'approved') {
          dispatch(saveCpf(cpf));
          dispatch(changeStage(SELFIE_STAGE));
        } else {
          dispatch(changeStage(INACTIVE_STAGE));
        }
      })
      .catch(error => {
        const {
          response: { status },
        } = error;
        if (status === 404) {
          dispatch(changeStage(INACTIVE_STAGE));
        } else {
          handleError(error);
        }
      })
      .finally(() => dispatch(setLoading(false)));
  };
}

export function uploadSelfie(cpf, file, hasExtension) {
  return dispatch => {
    dispatch({ type: UPLOAD_SELFIE });
    dispatch(setLoading(true));

    return api
      .sendPinResetSelfie(cpf, file)
      .then(resetId => {
        if (hasExtension) {
          extension.notifyPinResetRequest(resetId);
        }
        dispatch(changeStage(REQUESTED_STAGE));
      })
      .catch(handleError)
      .finally(() => dispatch(setLoading(false)));
  };
}

export function resetPin(token, pin, hasExtension) {
  return dispatch => {
    dispatch({ type: RESET_PIN });
    dispatch(setLoading(true));

    return api
      .resetPin(token, pin)
      .then(() => {
        if (hasExtension) {
          extension.notifyPinReset();
        }
        dispatch(changeStage(FINISHED_STAGE));
      })
      .catch(error => {
        const { response } = error;
        if (response) {
          dispatch(changeStage(EXPIRED_STAGE));
        } else {
          handleError(error);
        }
      })
      .finally(() => dispatch(setLoading(false)));
  };
}
